import React, { useContext, useEffect, useState, useRef } from 'react'
import AppLayout from '../layouts/AppLayout'
import { AppContext } from '../contexts/AppContext'
import Spinner from 'react-activity/dist/Spinner'
import 'react-activity/dist/Spinner.css'
import { format } from 'date-fns'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { IoIosArrowForward } from 'react-icons/io'
import { GrDownload } from 'react-icons/gr'
import { FaXTwitter, FaTiktok, FaTelegram, FaDiscord, FaSnapchat, FaSquareXTwitter } from 'react-icons/fa6'
import { FaTelegramPlane, FaSnapchatGhost } from 'react-icons/fa'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

const Report = () => {
  const {
    getreportmatchdays,
    getoverviewdaymatches,
    getoverviewdaywebsites,
    getoverviewmatchdaydata,
    getoverviewmatchdaydatatotals,
    isLoading,
  } = useContext(AppContext)
  const [slctdCompetition, setslctdCompetition] = useState(1)
  const [matchdays, setMatchdays] = useState([])
  const [matchday, setMatchday] = useState()
  const [matches, setMatches] = useState([])
  const [websites, setWebsites] = useState([])
  const [matchdayData, setMatchdayData] = useState([])
  const [matchdayDataTotals, setMatchdayDataTotals] = useState([])
  const [matchdayDataSpectators, setMatchdayDataSpectators] = useState(0)
  const [highlightWebsitesTotal, setHighlightWebsitesTotal] = useState(0)
  const [liveWebsitesTotal, setLiveWebsitesTotal] = useState(0)
  const [reportLoading, setReportLoading] = useState(false)
  const requestRef = useRef(0)
  const [ddlTotal, setDdlTotal] = useState(0)

  const onPrint = (reportId) => {
    const element = document.getElementById(reportId)
    html2canvas(element).then((canvas) => {
      let pdf = new jsPDF('p', 'px', [element.clientWidth, element.clientHeight])
      pdf.height = element.clientHeight
      pdf.width = element.clientWidth //this is the canvas resolution
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, element.clientWidth, element.clientHeight) //this is the image resolution
      pdf.save('download.pdf')
    })

    // const pdf = new jsPDF('p', 'px', 'a4')
    // pdf.height = element.clientHeight
    // pdf.width = element.clientWidth

    // const opt = {
    //   callback: function (jsPdf) {
    //     window.open(jsPdf.output('bloburl'))
    //   },
    //   margin: [0, 0, 0, 0],
    //   autoPaging: 'text',
    //   html2canvas: {
    //     // dpi: 300,
    //     // letterRendering: true,
    //     logging: true,
    //     // scale: 0.37,
    //     // windowWidth: window.innerWidth,
    //     width: 2480,
    //     height: 3508,
    //   },
    // }
    // pdf.html(element, opt)

    // html2canvas(element, {
    //   // scale: 0.9,
    //   // height: element.scrollHeight,
    //   // windowWidth: element.clientWidth,
    //   // scrollY: -element.scrollHeight,
    //   logging: true,
    //   scrollX: -window.scrollX,
    //   scrollY: -window.scrollY,
    //   windowWidth: element.clientWidth,
    //   windowHeight: element.clientHeight,
    // }).then((canvas) => {
    //   const img = new Image()

    //   const imgData = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')

    //   const pdfWidth = pdf.internal.pageSize.getWidth()
    //   const pdfHeight = pdf.internal.pageSize.getHeight()

    //   // pdf.save()

    //   // const imgData = canvas.toDataURL('image/png')
    //   // const pdf = new jsPDF()
    //   // pdf.addImage(imgData, 'JPEG', 0, 0)
    //   // // pdf.output('dataurlnewwindow');
    //   // pdf.save('download.pdf')
    // })
  }

  const fetchMatchDays = async () => {
    const response = await getreportmatchdays(slctdCompetition)
    setMatchdays(response.matchdays)
  }

  const fetchMatchDaysMatches = async () => {
    const response = await getoverviewdaymatches(matchday, slctdCompetition)
    setMatches(response)
  }

  const fetchMatchDayWebsites = async () => {
    setReportLoading(true)
    const [websitesResponse, totalsResponse] = await Promise.all([
      getoverviewdaywebsites(matchday, slctdCompetition),
      getoverviewmatchdaydatatotals(matchday, slctdCompetition),
    ])

    setWebsites(websitesResponse)

    // the total response is wrong here

    const template = Object.keys(totalsResponse.matchLinksTotals[0])

    const dataTotals = {
      totalgooglelinks: 0,
      totalhighlightlinks: 0,
      totallinks: 0,
      totallivelinks: 0,
    }

    totalsResponse.matchLinksTotals.forEach((record) => {
      dataTotals.totalgooglelinks += record.totalgooglelinks
      dataTotals.totalhighlightlinks += record.totalhighlightlinks
      dataTotals.totallinks += record.totallinks
      dataTotals.totallivelinks += record.totallivelinks
    })

    setMatchdayDataTotals(dataTotals)

    const totalHighlightLinks = websitesResponse.reduce((sum, website) => sum + (website.highlightlinks || 0), 0)
    setHighlightWebsitesTotal(totalHighlightLinks)

    const updatedWebsites = websitesResponse.map((item) => {
      if (item.name === 'DDL') {
        setDdlTotal(item.totallinks)
      }
      if (item.name === 'Google' || item.name === 'Bing') {
        item.livelinks = dataTotals.totalgooglelinks
        item.highlightlinks = Math.max(0, dataTotals.totalhighlightlinks - totalHighlightLinks)
      }
      return item
    })
    setWebsites(updatedWebsites)

    setMatchdayDataSpectators(totalsResponse.totalSpectators)
    setDdlTotal(totalsResponse.totalddl)
    setReportLoading(false)
  }

  const fetchMatchDayData = async () => {
    const response = await getoverviewmatchdaydata(matchday, slctdCompetition)
    setMatchdayData(response[0])
  }

  const onClickMatchDay = (day) => {
    const url = new URL(window.location.href)
    url.searchParams.set('report', day.matchday)
    window.history.pushState(null, null, url)

    setMatchday(matchday === day.matchday ? '' : day.matchday)
  }

  useEffect(() => {
    if (matchday > 0) {
      setMatches([])
      setWebsites([])
      setMatchdayData([])
      setMatchdayDataTotals([])
      setMatchdayDataSpectators(0)
      setHighlightWebsitesTotal(0)
      setLiveWebsitesTotal(0)
      fetchMatchDayData()
      fetchMatchDaysMatches()
      fetchMatchDayWebsites()
    }
  }, [matchday])

  useEffect(() => {
    fetchMatchDays()
  }, [slctdCompetition])

  useEffect(() => {
    const url = new URL(window.location.href)
    const activeDay = url.searchParams.get('report')
    if (activeDay && matchdays.length && matchdays.some(md => md.matchday === activeDay)) {
      setMatchday(activeDay)
    }
  }, [matchdays])

  const competitions = [1, 2]

  return (
    <AppLayout pageName={'Rapports Journées'}>
      {isLoading ? (
        <div className='flex justify-center w-full py-12'>
          <Spinner color='#CCCCCC' size={24} />
        </div>
      ) : (
        <>
          <div className='space-y-5'>
            <section>
              <div className='flex flex-wrap flex-col lg:flex-row justify-between'>
                <div className='inline-flex rounded-md mb-3'>
                  {competitions.map((competition, index) => {
                    return (
                      <button
                        onClick={() => setslctdCompetition(competition)}
                        aria-current='page'
                        className={`px-4 py-2 text-sm font-medium tracking-wider ${
                          competition === slctdCompetition
                            ? 'text-white bg-main'
                            : 'text-neutral-800 bg-white border border-gray-200 hover:bg-gray-100'
                        }`}
                      >
                        Ligue {competition}
                      </button>
                    )
                  })}
                </div>
              </div>

              <div className='space-y-3'>
                {matchdays.map((day, index) => {
                  return (
                    <section className='!p-0' key={index}>
                      <div
                        className={`py-5 px-5 rounded-lg bg-white hover:bg-neutral-50 transition-colors cursor-pointer flex flex-row justify-between space-x-2 items-center ${
                          matchday === day.matchday && 'border-b'
                        }`}
                        onClick={() => onClickMatchDay(day)}
                      >
                        <h5 className='text-base leading-none font-medium tracking-wide'>Journée {day.matchday}</h5>
                        <div className='flex gap-4 items-center'>
                          <button
                            className='btn-main'
                            onClick={(event) => {
                              event.stopPropagation()
                              onPrint(`report-${day.matchday}`)
                            }}
                          >
                            <GrDownload />
                          </button>

                          <span className={`${matchday === day.matchday && 'rotate-90'}`}>
                            <IoIosArrowForward />
                          </span>
                        </div>
                      </div>
                      {matchday === day.matchday && (
                        <>
                          {reportLoading ? (
                            <div className='flex justify-center w-full py-12'>
                              <Spinner color='#CCCCCC' size={24} />
                            </div>
                          ) : (
                            <div id={`report-${day.matchday}`} className='space-y-10 px-5 py-7'>
                              <div>
                                <div>
                                  <div className='bg-white border rounded-lg shadow-lg p-6 pb-8'>
                                    <div className='text-main text-3xl font-extrabold uppercase'>PIRATAGE Live</div>
                                  </div>
                                  <div className='rounded-xl border shadow mx-5 -mt-5 bg-main py-4'>
                                    <table className='w-full text-sm text-center text-gray-800'>
                                      <thead className='text-xs text-neutral-800 uppercase bg-main-alt'>
                                        <tr>
                                          <th
                                            colSpan={5}
                                            scope='col'
                                            className='px-6 pb-4 text-xl text-white bg-main capitalize'
                                          >
                                            Tableau récapitulatif pour les liens live
                                          </th>
                                        </tr>
                                        <tr>
                                          <th scope='col' className='px-6 py-4 text-left'>
                                            Plateformes
                                          </th>
                                          <th scope='col' className='px-6 py-4'>
                                            Nombre de liens live
                                          </th>
                                          <th scope='col' className='px-6 py-4'>
                                            Moyenne de temps de fermeture
                                          </th>
                                          <th scope='col' className='px-6 py-4'>
                                            Taux de fermeture
                                          </th>
                                          <th scope='col' className='px-6 py-4'>
                                            Nombre de Spectateurs
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {websites
                                          .slice()
                                          .sort((a, b) => b.livelinks - a.livelinks)
                                          .map((website, index) => {
                                            return (
                                              <>
                                                {website.name !== 'Snapchat' && website.name !== 'DDL' && (
                                                  <tr className='bg-white border-b-4 border-blue-100'>
                                                    <th
                                                      scope='row'
                                                      className='px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left'
                                                    >
                                                      <div className='flex space-x-2 items-center'>
                                                        <img
                                                          width='20'
                                                          height='20'
                                                          src={`/images/icons/${website.icon}.png`}
                                                        />
                                                        <div>
                                                          <h6 className='text-neutral-900 text-lg'>{website.name}</h6>
                                                          <h6 className='text-neutral-500 text-xs'>
                                                            {(website.name === 'Google' || website.name === 'Bing') &&
                                                              'déréférencement'}
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </th>
                                                    <td className='px-6 py-4 text-lg border-r-4 border-main'>
                                                      {website.name === 'Google' || website.name === 'Bing'
                                                        ? matchdayDataTotals.totalgooglelinks > 0
                                                          ? matchdayDataTotals.totalgooglelinks.toLocaleString()
                                                          : 0
                                                        : website.livelinks > 0
                                                        ? website.livelinks.toLocaleString()
                                                        : 0}
                                                    </td>
                                                    <td className='px-6 py-4 text-lg border-r-4 border-main'>
                                                      {website.time !== null && website.time}
                                                    </td>
                                                    <td className='px-6 py-4 text-lg border-r-4 border-main'>
                                                      {website.rate !== null && website.rate + '%'}
                                                    </td>
                                                    <td className='px-6 py-4 text-lg'>
                                                      {website.totalspectators === 0 || website.totalspectators === null
                                                        ? '-'
                                                        : website.totalspectators.toLocaleString()}
                                                    </td>
                                                  </tr>
                                                )}
                                              </>
                                            )
                                          })}
                                      </tbody>
                                      <thead className='text-lg text-neutral-800 uppercase bg-main-alt'>
                                        <tr>
                                          <th scope='col' className='px-6 py-4 text-left'>
                                            TOTAL
                                          </th>
                                          <th scope='col' className='px-6 py-4'>
                                            {matchdayDataTotals.totallivelinks + matchdayDataTotals.totalgooglelinks > 0
                                              ? (
                                                  matchdayDataTotals.totallivelinks +
                                                  matchdayDataTotals.totalgooglelinks
                                                ).toLocaleString()
                                              : '-'}{' '}
                                            LIENS
                                            {/* {(liveWebsitesTotal > 0) ? (liveWebsitesTotal).toLocaleString() : '-'} LIENS */}
                                          </th>
                                          <th></th>
                                          <th></th>
                                          <th scope='col' className='px-6 py-4'>
                                            {matchdayDataSpectators > 0 ? matchdayDataSpectators.toLocaleString() : '-'}{' '}
                                            SPECTATEURS
                                          </th>
                                        </tr>
                                      </thead>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div>
                                  <div className='bg-white border rounded-lg shadow-lg p-6 pb-8'>
                                    <div className='text-main text-3xl font-extrabold uppercase'>
                                      PIRATAGE HIGHLIGHTS
                                    </div>
                                  </div>
                                  <div className='rounded-xl border shadow mx-5 -mt-5 bg-main py-4'>
                                    <table className='w-full text-sm text-center text-gray-800'>
                                      <thead className='text-xs text-neutral-800 uppercase bg-main-alt'>
                                        <tr>
                                          <th
                                            colSpan={3}
                                            scope='col'
                                            className='px-6 pb-4 text-xl text-white bg-main capitalize'
                                          >
                                            Tableau récapitulatif pour les liens highlights
                                          </th>
                                        </tr>
                                        <tr>
                                          <th scope='col' className='px-6 py-4 text-left'>
                                            Plateformes
                                          </th>
                                          <th scope='col' className='px-6 py-4'>
                                            Nombre de liens highlights notifiés
                                          </th>
                                          <th scope='col' className='px-6 py-4'>
                                            Moyenne de temps de fermeture
                                          </th>
                                          <th scope='col' className='px-6 py-4'>
                                            Taux de fermeture
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {websites
                                          .slice()
                                          .sort((a, b) => b.highlightlinks - a.highlightlinks)
                                          .map((website, index) => {
                                            return (
                                              <tr className='bg-white border-b-4 border-blue-100'>
                                                <th
                                                  scope='row'
                                                  className='px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left'
                                                >
                                                  <div className='flex space-x-2 items-center'>
                                                    <img
                                                      width='20'
                                                      height='20'
                                                      src={`/images/icons/${website.icon}.png`}
                                                    />
                                                    <div>
                                                      <h6 className='text-neutral-900 text-lg'>{website.name}</h6>
                                                      <h6 className='text-neutral-500 text-xs'>
                                                        {(website.name === 'Google' || website.name === 'Bing') &&
                                                          'déréférencement'}
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </th>
                                                <td className='px-6 py-4 text-lg border-r-4 border-main'>
                                                  {/* {((website.highlightlinks > 0) ? (website.highlightlinks).toLocaleString() : 0)} */}
                                                  {website.name === 'Google' || website.name === 'Bing'
                                                    ? matchdayDataTotals.totalhighlightlinks - highlightWebsitesTotal >
                                                      0
                                                      ? (
                                                          matchdayDataTotals.totalhighlightlinks -
                                                          highlightWebsitesTotal
                                                        ).toLocaleString()
                                                      : 0
                                                    : website.highlightlinks > 0
                                                    ? website.highlightlinks.toLocaleString()
                                                    : 0}
                                                </td>
                                                <td className='px-6 py-4 text-lg border-r-4 border-main'>
                                                  {website.highlighttime !== null && website.highlighttime}
                                                </td>
                                                <td className='px-6 py-4 text-lg'>
                                                  {website.highlightrate !== null && website.highlightrate + '%'}
                                                </td>
                                              </tr>
                                            )
                                          })}
                                      </tbody>
                                      <thead className='text-lg text-neutral-800 uppercase bg-main-alt'>
                                        <tr>
                                          <th scope='col' className='px-6 py-4 text-left'>
                                            TOTAL
                                          </th>
                                          <th scope='col' className='px-6 py-4'>
                                            {matchdayDataTotals.totalhighlightlinks - ddlTotal > 0
                                              ? (matchdayDataTotals.totalhighlightlinks - ddlTotal).toLocaleString()
                                              : '-'}{' '}
                                            LIENS
                                          </th>
                                          <th></th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div>
                                  <div className='bg-white border rounded-lg shadow-lg p-6 pb-8'>
                                    <div className='text-xs leading-none text-main-alt font-medium'>TOTAL</div>
                                    <div className='text-main text-3xl font-extrabold uppercase'>TOP ÉVÉNEMENTS</div>
                                  </div>
                                  <div className='rounded-xl border shadow mx-5 -mt-5 bg-main py-4'>
                                    <table className='w-full text-sm text-center text-gray-800'>
                                      <thead className='text-xs text-white uppercase bg-main'>
                                        <tr>
                                          <th scope='col' className='px-6 pb-4 text-left'>
                                            Event
                                          </th>
                                          <th scope='col' className='px-6 pb-4'>
                                            Date
                                          </th>
                                          <th scope='col' className='px-6 pb-4'>
                                            Total des liens
                                          </th>
                                        </tr>
                                        <tr>
                                          <th
                                            colSpan={3}
                                            scope='col'
                                            className='px-6 py-3 text-left bg-main-alt capitalize text-neutral-800'
                                          >
                                            Total (Live, Highlights, Google)
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {matches
                                          .slice()
                                          .sort((a, b) => b.totallinks - a.totallinks)
                                          .map((match, index) => (
                                            <tr className='bg-white border-b-4 border-blue-100'>
                                              <th
                                                scope='row'
                                                className='px-6 py-5 font-medium border-r-4 border-main whitespace-nowrap text-left'
                                              >
                                                {match.team1name} - {match.team2name}
                                              </th>
                                              <td className='px-6 py-5 border-r-4 border-main'>
                                                {format(match.date, 'dd/MM/yyyy')}
                                              </td>
                                              <td className='px-6 py-5'>
                                                {match.totallinks > 0 ? match.totallinks.toLocaleString() : ''}
                                              </td>
                                            </tr>
                                          ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div>
                                  <div className='bg-white border rounded-lg shadow-lg p-6 pb-8'>
                                    <div className='text-xs leading-none text-main-alt font-medium'>DIRECT</div>
                                    <div className='text-main text-3xl font-extrabold uppercase'>TOP ÉVÉNEMENTS</div>
                                  </div>
                                  <div className='rounded-xl border shadow mx-5 -mt-5 bg-main py-4'>
                                    <table className='w-full text-sm text-center text-gray-800'>
                                      <thead className='text-xs text-white uppercase bg-main'>
                                        <tr>
                                          <th scope='col' className='px-6 pb-4 text-left'>
                                            Event
                                          </th>
                                          <th scope='col' className='px-6 pb-4'>
                                            Date
                                          </th>
                                          <th scope='col' className='px-6 pb-4'>
                                            Total des liens
                                          </th>
                                        </tr>
                                        <tr>
                                          <th
                                            colSpan={3}
                                            scope='col'
                                            className='px-6 py-3 text-left bg-main-alt capitalize text-neutral-800'
                                          >
                                            Live
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {matches
                                          .slice()
                                          .sort((a, b) => b.livelinks - a.livelinks)
                                          .map((match, index) => (
                                            <tr className='bg-white border-b-4 border-blue-100'>
                                              <th
                                                scope='row'
                                                className='px-6 py-5 font-medium border-r-4 border-main whitespace-nowrap text-left'
                                              >
                                                {match.team1name} - {match.team2name}
                                              </th>
                                              <td className='px-6 py-5 border-r-4 border-main'>
                                                {format(match.date, 'dd/MM/yyyy')}
                                              </td>
                                              <td className='px-6 py-5'>
                                                {match.livelinks > 0 ? match.livelinks.toLocaleString() : ''}
                                              </td>
                                            </tr>
                                          ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div>
                                  <div className='bg-white border rounded-lg shadow-lg p-6 pb-8'>
                                    <div className='text-xs leading-none text-main-alt font-medium'>SPECTATEURS</div>
                                    <div className='text-main text-3xl font-extrabold uppercase'>TOP ÉVÉNEMENTS</div>
                                  </div>
                                  <div className='rounded-xl border shadow mx-5 -mt-5 bg-main py-4'>
                                    <table className='w-full text-sm text-center text-gray-800'>
                                      <thead className='text-xs text-white uppercase bg-main'>
                                        <tr>
                                          <th scope='col' className='px-6 pb-4 text-left'>
                                            Event
                                          </th>
                                          <th scope='col' className='px-6 pb-4'>
                                            Date
                                          </th>
                                          <th scope='col' className='px-6 pb-4'>
                                            NOMBRE DE SPECTATEURS
                                          </th>
                                        </tr>
                                        <tr>
                                          <th
                                            colSpan={3}
                                            scope='col'
                                            className='px-6 py-3 text-left bg-main-alt capitalize text-neutral-800'
                                          >
                                            Spectateurs
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {matches
                                          .slice()
                                          .sort((a, b) => b.totalspectators - a.totalspectators)
                                          .map((match, index) => (
                                            <tr className='bg-white border-b-4 border-blue-100'>
                                              <th
                                                scope='row'
                                                className='px-6 py-5 font-medium border-r-4 border-main whitespace-nowrap text-left'
                                              >
                                                {match.team1name} - {match.team2name}
                                              </th>
                                              <td className='px-6 py-5 border-r-4 border-main'>
                                                {format(match.date, 'dd/MM/yyyy')}
                                              </td>
                                              <td className='px-6 py-5'>
                                                {match.totalspectators > 0
                                                  ? match.totalspectators.toLocaleString()
                                                  : ''}
                                              </td>
                                            </tr>
                                          ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div>
                                  <div className='bg-white border rounded-lg shadow-lg p-6 pb-8'>
                                    <div className='text-xs leading-none text-main-alt font-medium'>HIGHLIGHTS</div>
                                    <div className='text-main text-3xl font-extrabold uppercase'>TOP ÉVÉNEMENTS</div>
                                  </div>
                                  <div className='rounded-xl border shadow mx-5 -mt-5 bg-main py-4'>
                                    <table className='w-full text-sm text-center text-gray-800'>
                                      <thead className='text-xs text-white uppercase bg-main'>
                                        <tr>
                                          <th scope='col' className='px-6 pb-4 text-left'>
                                            Event
                                          </th>
                                          <th scope='col' className='px-6 pb-4'>
                                            Date
                                          </th>
                                          <th scope='col' className='px-6 pb-4'>
                                            Total des liens
                                          </th>
                                        </tr>
                                        <tr>
                                          <th
                                            colSpan={3}
                                            scope='col'
                                            className='px-6 py-3 text-left bg-main-alt capitalize text-neutral-800'
                                          >
                                            Highlights
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {matches
                                          .slice()
                                          .sort((a, b) => b.highlightlinks - a.highlightlinks)
                                          .map((match, index) => (
                                            <tr className='bg-white border-b-4 border-blue-100'>
                                              <th
                                                scope='row'
                                                className='px-6 py-5 font-medium border-r-4 border-main whitespace-nowrap text-left'
                                              >
                                                {match.team1name} - {match.team2name}
                                              </th>
                                              <td className='px-6 py-5 border-r-4 border-main'>
                                                {format(match.date, 'dd/MM/yyyy')}
                                              </td>
                                              <td className='px-6 py-5'>
                                                {match.highlightlinks > 0 ? match.highlightlinks.toLocaleString() : ''}
                                              </td>
                                            </tr>
                                          ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div>
                                  <div className='bg-white border rounded-lg shadow-lg p-6 pb-8'>
                                    <div className='text-main text-3xl font-extrabold uppercase'>
                                      COMPTES NOTIFIÉS ET SUSPENDUS
                                    </div>
                                    <div className='flex flex-wrap space-x-5 mt-3 ml-1 text-black text-lg'>
                                      <div className='flex space-x-2 items-center'>
                                        <FaSquareXTwitter /> <span>X / Twitter</span>
                                      </div>
                                      <div className='flex space-x-2 items-center'>
                                        <FaTiktok /> <span>Tiktok</span>
                                      </div>
                                      <div className='flex space-x-2 items-center'>
                                        <FaTelegramPlane /> <span>Telegram</span>
                                      </div>
                                      <div className='flex space-x-2 items-center'>
                                        <FaDiscord /> <span>Discord</span>
                                      </div>
                                      <div className='flex space-x-2 items-center'>
                                        <FaSnapchatGhost /> <span>Snapchat</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='rounded-xl border shadow mx-5 -mt-5 bg-white'>
                                    <div className='rounded-t-xl bg-main p-4'>
                                      <h4 className='text-white font-semibold text-2xl text-center'>
                                        Comptes Suspendus
                                      </h4>
                                    </div>
                                    <div className='p-10x acc-table'>
                                      <table className='w-full text-sm text-center text-gray-800'>
                                        <tr className='bg-white border-b-4 border-blue-100'>
                                          <th
                                            scope='row'
                                            className='px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left w-1/2'
                                          >
                                            <div className='flex space-x-2 items-center'>
                                              <img width='20' height='20' src={`/images/icons/x.png`} />
                                              <h6 className='text-neutral-900 text-lg'>X / Twitter</h6>
                                            </div>
                                          </th>
                                          <td className='px-6 py-5 text-lg'>
                                            {matchdayData && matchdayData.accsuspendedtwitter > 0
                                              ? matchdayData.accsuspendedtwitter.toLocaleString()
                                              : ''}
                                          </td>
                                        </tr>
                                        <tr className='bg-white border-b-4 border-blue-100'>
                                          <th
                                            scope='row'
                                            className='px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left'
                                          >
                                            <div className='flex space-x-2 items-center'>
                                              <img width='20' height='20' src={`/images/icons/tiktok.png`} />
                                              <h6 className='text-neutral-900 text-lg'>Tiktok</h6>
                                            </div>
                                          </th>
                                          <td className='px-6 py-5 text-lg'>
                                            {matchdayData && matchdayData.accsuspendedtiktok > 0
                                              ? matchdayData.accsuspendedtiktok.toLocaleString()
                                              : ''}
                                          </td>
                                        </tr>
                                        <tr className='bg-white border-b-4 border-blue-100'>
                                          <th
                                            scope='row'
                                            className='px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left'
                                          >
                                            <div className='flex space-x-2 items-center'>
                                              <img width='20' height='20' src={`/images/icons/telegram.png`} />
                                              <div>
                                                <h6 className='text-neutral-900 text-lg'>Telegram</h6>
                                                <h6 className='text-neutral-500 text-xs'>Canaux suspendus</h6>
                                              </div>
                                            </div>
                                          </th>
                                          <td className='px-6 py-5 text-lg'>
                                            {matchdayData && matchdayData.accsuspendedtelegram > 0
                                              ? matchdayData.accsuspendedtelegram.toLocaleString()
                                              : ''}
                                          </td>
                                        </tr>
                                        <tr className='bg-white'>
                                          <th
                                            scope='row'
                                            className='px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left'
                                          >
                                            <div className='flex space-x-2 items-center'>
                                              <img width='20' height='20' src={`/images/icons/discord.png`} />
                                              <div>
                                                <h6 className='text-neutral-900 text-lg'>Discord</h6>
                                                <h6 className='text-neutral-500 text-xs'>Serveurs suspendus</h6>
                                              </div>
                                            </div>
                                          </th>
                                          <td className='px-6 py-5 text-lg'>
                                            {matchdayData && matchdayData.accsuspendeddiscord > 0
                                              ? matchdayData.accsuspendeddiscord.toLocaleString()
                                              : ''}
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                  <div className='rounded-xl border shadow mx-5 mt-3 bg-white'>
                                    <div className='rounded-t-xl bg-main-alt p-4'>
                                      <h4 className='text-black font-semibold text-2xl text-center'>
                                        Comptes Notifies
                                      </h4>
                                    </div>
                                    <div className='p-10x'>
                                      <table className='w-full text-sm text-center text-gray-800'>
                                        <tr className='bg-white border-b-4 border-blue-100'>
                                          <th
                                            scope='row'
                                            className='px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left w-1/2'
                                          >
                                            <div className='flex space-x-2 items-center'>
                                              <img width='20' height='20' src={`/images/icons/x.png`} />
                                              <h6 className='text-neutral-900 text-lg'>X / Twitter</h6>
                                            </div>
                                          </th>
                                          <td className='px-6 py-5 text-lg'>
                                            {matchdayData && matchdayData.accnotifiedtwitter > 0
                                              ? matchdayData.accnotifiedtwitter.toLocaleString()
                                              : ''}
                                          </td>
                                        </tr>
                                        <tr className='bg-white border-b-4 border-blue-100'>
                                          <th
                                            scope='row'
                                            className='px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left'
                                          >
                                            <div className='flex space-x-2 items-center'>
                                              <img width='20' height='20' src={`/images/icons/tiktok.png`} />
                                              <h6 className='text-neutral-900 text-lg'>Tiktok</h6>
                                            </div>
                                          </th>
                                          <td className='px-6 py-5 text-lg'>
                                            {matchdayData && matchdayData.accnotifiedtiktok > 0
                                              ? matchdayData.accnotifiedtiktok.toLocaleString()
                                              : ''}
                                          </td>
                                        </tr>
                                        {/* <tr className="bg-white border-b-4 border-blue-100">
                                      <th scope="row" className="px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left">
                                        <div className="flex space-x-2 items-center">
                                            <img width="20" height="20" src={`/images/icons/telegram.png`} />
                                            <h6 className="text-neutral-900 text-lg">Telegram</h6>
                                        </div>
                                      </th>
                                      <td className="px-6 py-5 text-lg">{matchdayData && matchdayData.accnotifiedtelegram}</td>
                                  </tr> */}
                                        <tr className='bg-white'>
                                          <th
                                            scope='row'
                                            className='px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left'
                                          >
                                            <div className='flex space-x-2 items-center'>
                                              <img width='20' height='20' src={`/images/icons/snapchat.png`} />
                                              <h6 className='text-neutral-900 text-lg'>Snapchat</h6>
                                            </div>
                                          </th>
                                          <td className='px-6 py-5 text-lg'>
                                            {matchdayData && matchdayData.accnotifiedsnapchat > 0
                                              ? matchdayData.accnotifiedsnapchat.toLocaleString()
                                              : ''}
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                  <div className='rounded-xl border shadow mx-5 mt-3 bg-white'>
                                    <div className='rounded-t-xl bg-main-alt p-4'>
                                      <h4 className='text-black font-semibold text-2xl text-center'>
                                        Membres Impactés
                                      </h4>
                                    </div>
                                    <div className='p-10x'>
                                      <table className='w-full text-sm text-center text-gray-800'>
                                        <tr className='bg-white'>
                                          <th
                                            scope='row'
                                            className='px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left w-1/2'
                                          >
                                            <div className='flex space-x-2 items-center'>
                                              <img width='20' height='20' src={`/images/icons/telegram.png`} />
                                              <h6 className='text-neutral-900 text-lg'>Telegram</h6>
                                            </div>
                                          </th>
                                          <td className='px-6 py-5 text-lg'>
                                            {matchdayData && matchdayData.membersinspactedtelegram > 0
                                              ? matchdayData.membersinspactedtelegram.toLocaleString()
                                              : ''}
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </section>
                  )
                })}
              </div>
            </section>
          </div>
        </>
      )}
    </AppLayout>
  )
}

export default Report
