import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/auth.css";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { MdOutlineCopyright } from "react-icons/md";

const updateThemeColor = (color) => {
  // Find the meta tag or create one if it doesn't exist
  let themeColorMetaTag = document.querySelector('meta[name="theme-color"]');
  if (!themeColorMetaTag) {
    themeColorMetaTag = document.createElement("meta");
    themeColorMetaTag.setAttribute("name", "theme-color");
    document.getElementsByTagName("head")[0].appendChild(themeColorMetaTag);
  }

  // Update the content attribute to the new color
  themeColorMetaTag.setAttribute("content", color);
};

const AuthLayout = ({ children }) => {
  useEffect(() => {
    updateThemeColor("#042B56"); // Set theme color for the home page
  }, []);

  return (
    <div className="auth-screens">
      <div className="min-h-screen flex flex-col justify-center container py-6 md:py-20 mx-auto">
        <div className="w-full block md:flex overflow-hidden h-full">
          <div className="w-full md:w-1/2 section-left">
            {/* <img className="auth-dots" src="/images/auth-dots.png" /> */}
            <div className="bg-overlay">
              <div className="w-full">
                <Link to={"/"}>
                  <img width="200" alt="Protect Artists Logo" className="invert auth-logo" src="/logo.png" />
                </Link>
              </div>
              <div className="w-full py-6 md:py-10">
                <div className="section-left-content w-full lg:w-11/12">
                  <h2 className="inter">Protect - Artists x Ligue de Football Professionnel</h2>
                </div>
              </div>
              <div className="w-full hidden md:block">
                <div className="flex flex-col-reverse md:flex-row lg:pr-5 justify-between text-sm font-normal text-neutral-300">
                  <div className="space-y-2 mt-10 md:mt-0 opacity-50 md:opacity-100">
                    <div className="flex space-x-1 items-center justify-center md:justify-start">
                      <MdOutlineCopyright className="hidden md:block" size={18} /> <span>2024 Protect Artists</span>
                    </div>
                    {/* <a href="mailto:info@protectartists.com" className="flex space-x-1 items-center justify-center md:justify-start hover:!underline hover:text-[#042B56]">
                      <MdOutlineAlternateEmail className="hidden md:block" size={18} /> <span>info@protectartists.com</span>
                    </a> */}
                  </div>
                  {/* <div className="flex space-x-8 md:block justify-center md:justify-normal space-y-0 md:space-y-2 text-center md:text-right">
                    <div>
                      <Link to={"/terms"} className="hover:!underline">
                        Terms &amp; Conditions
                      </Link>
                    </div>
                    <div>
                      <Link to={"/privacy"} className="hover:!underline">
                        Privacy Policy
                      </Link>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 section-right">
            <div className="h-full flex flex-col justify-between">
              <div className="w-full flex flex-1 flex-col justify-center">
                <div className="mx-auto form-wrapper">{children}</div>
              </div>
            </div>
            <div className="w-full block md:hidden mt-5 text-sm font-normal text-neutral-300">
              <div className="flex space-x-1 items-center justify-center md:justify-start">
                <MdOutlineCopyright className="hidden md:block" size={18} /> <span>2024 Protect Artists</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
