import AppLayout from '../layouts/AppLayout'
import React, { Fragment, useState, useEffect, useContext } from 'react'
import { AppContext } from '../contexts/AppContext'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { RxCross2 } from 'react-icons/rx'
import Spinner from 'react-activity/dist/Spinner'
import 'react-activity/dist/Spinner.css'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { FiPlus } from 'react-icons/fi'
import Tooltip from '../components/Tooltip'
import { FiEdit } from 'react-icons/fi'
import { RiDeleteBinLine } from 'react-icons/ri'
import { TbSwitchHorizontal } from 'react-icons/tb'
import '../styles/players.css'
import Swal from 'sweetalert2'
import classNames from 'classnames'
import { getTeamImage } from '../utils/team'

const TeamDetails = () => {
  const { teamId } = useParams()

  const navigate = useNavigate()

  const [team, setTeam] = useState('')
  const [teams, setTeams] = useState([])
  const [tab, setTab] = useState('Matches')
  const [matches, setMatches] = useState([])
  const [players, setPlayers] = useState([])
  const [toEdit, setToEdit] = useState('')
  const [toSwitch, setToSwitch] = useState('')
  const [sname, setsName] = useState('')
  const [sposition, setsPosition] = useState('')
  const [steam, setsTeam] = useState('')
  const [mLoading, setmLoading] = useState(false)
  const [sLoading, setsLoading] = useState(false)
  const [showForm, setshowForm] = useState(false)
  const [formLoading, setformLoading] = useState(false)
  const [modalopen, setModalopen] = useState(false)
  const [formError, setformError] = useState('')
  const [name, setName] = useState('')
  const [position, setPosition] = useState('')

  const {
    getallteams,
    getteam,
    getteammatches,
    getteamplayers,
    addplayer,
    updateplayer,
    getplayer,
    deleteplayer,
    switchplayer,
    isLoading,
    userToken,
  } = useContext(AppContext)

  const positionarr = ['Attaquant', 'Milieu', 'Défenseur', 'Gardien']
  const colorsarr = ['bg-red-500', 'bg-orange-400', 'bg-green-400', 'bg-gray-400']

  const fetchTeam = async () => {
    const response = await getteam(teamId)
    if (response) {
      if (response[0] !== '') {
        setTeam(response[0])
      } else {
        navigate('/teams')
      }
    } else {
      navigate('/teams')
    }
  }

  const fetchTeams = async () => {
    const response = await getallteams()
    setTeams(response)
  }

  useEffect(() => {
    if (teamId) {
      fetchTeam()
      fetchTeams()
    }
  }, [teamId])

  const fetchTeamMatches = async () => {
    setmLoading(true)
    const response = await getteammatches(teamId)
    setMatches(response)
    setmLoading(false)
  }

  const fetchTeamPlayers = async () => {
    setsLoading(true)
    const response = await getteamplayers(teamId)
    setPlayers(response)
    setsLoading(false)
  }

  useEffect(() => {
    if (tab === 'Matches') {
      fetchTeamMatches()
    } else if (tab === 'Effectif') {
      fetchTeamPlayers()
    }
  }, [tab])

  const handlePlayersSubmit = async (e) => {
    e.preventDefault()
    if (!isLoading && !formLoading) {
      try {
        setformLoading(true)
        if (setName !== '') {
          setformError('')
          let response
          if (toEdit !== '') {
            response = await updateplayer(name, position, toEdit)
          } else {
            response = await addplayer(name, position, teamId)
          }
          if (response === 'success') {
            setshowForm(false)
            setName('')
            setPosition('')
            fetchTeamPlayers()
            setToEdit('')
          }
        } else {
          setformError('All fields are required')
          return
        }
      } catch (error) {
        setformError('Error: ' + error)
      } finally {
        setformLoading(false)
      }
    }
  }

  const handlePlayersDelete = async (player) => {
    if (!isLoading && !sLoading) {
      try {
        const result = await Swal.fire({
          title: 'Are you sure to delete?',
          text: "You won't be able to undo this action",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Delete',
        })

        if (result.isConfirmed) {
          let response = await deleteplayer(player)
          if (response === 'success') {
            fetchTeamPlayers()
          }
        }
      } catch (error) {
        Swal.fire('An Error Occurred', error, 'error')
      }
    }
  }

  const handlePlayersEdit = async (player) => {
    setToEdit(player)
    let response = await getplayer(player)
    setsLoading(true)
    if (response) {
      setshowForm(true)
      setName(response[0].name)
      setPosition(response[0].position)
      setsLoading(false)
    }
  }

  const handleCancelUpdate = async () => {
    setToEdit('')
    setName('')
    setPosition('')
    setshowForm(false)
    setsLoading(false)
  }

  const switchPlayer = async () => {
    setsLoading(true)
    let response = await switchplayer(toSwitch, steam)
    setsLoading(true)
    if (response) {
      setModalopen(false)
      setsName('')
      setsPosition('')
      setsTeam('')
      fetchTeamPlayers()
    }
  }

  const handlePlayerSwitch = async (n, p, player) => {
    setToSwitch(player)
    setsName(n)
    setsPosition(p)
    setModalopen(true)
  }

  const closeModal = () => {
    setModalopen(false)
    setsTeam('')
  }

  return (
    <AppLayout pageName='Team Details'>
      {team !== '' ? (
        <>
          <section className='space-y-4 players'>
            <div className='p-2'>
              <div className='flex flex-row space-x-6 items-center'>
                <img
                  width='100'
                  height='100'
                  alt={team.name.toLowerCase().replaceAll(' ', '')}
                  src={getTeamImage(team?.logo, team?.name)}
                />{' '}
                <div>
                  <div className='text-sm text-neutral-500 tracking-wide font-medium mb-2'>{team.compname}</div>
                  <div className='text-5xl font-black uppercase tracking-wider heading text-black'>
                    {team.name}
                  </div>{' '}
                </div>
              </div>
            </div>
            <div className='text-sm font-medium text-center text-gray-500 border-b border-gray-200'>
              <ul className='flex flex-wrap -mb-px font-medium text-base tracking-wide'>
                <li className='me-2'>
                  <button
                    onClick={() => setTab('Matches')}
                    className={`inline-block px-5 py-3 border-b-2 ${
                      tab === 'Matches'
                        ? 'text-main-alt border-main-alt'
                        : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                    } rounded-t-lg active`}
                  >
                    Matches
                  </button>
                </li>
                <li className='me-2'>
                  <button
                    onClick={() => setTab('Effectif')}
                    className={`inline-block px-5 py-3 border-b-2 ${
                      tab === 'Effectif'
                        ? 'text-main-alt border-main-alt'
                        : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                    } rounded-t-lg active`}
                  >
                    Effectif
                  </button>
                </li>
              </ul>
            </div>

            <div>
              {tab === 'Matches' && (
                <>
                  {mLoading ? (
                    <div className='flex justify-center w-full py-12'>
                      <Spinner color='#CCCCCC' size={24} />
                    </div>
                  ) : matches.length > 0 ? (
                    <>
                      <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-5 py-5'>
                        {matches.map((match, index) => {
                          return (
                            <div key={index}>
                              <Link
                                to={`/${
                                  userToken.type == 'Admin' ? 'match-details' : 'matches/Ligue ' + team.competition
                                }/${match.matchid}`}
                              >
                                <div
                                  className={`py-4 px-5 rounded-lg bg-neutral-50 hover:bg-neutral-100 border transition-colors cursor-pointer`}
                                >
                                  <div className='flex flex-row justify-between headingx items-center divide-x'>
                                    <div className='w-8/12 space-y-4'>
                                      <div className='flex flex-row justify-between items-center'>
                                        <div className='flex flex-row space-x-4 text-lg font-semibold text-black items-center text-center'>
                                          <img
                                            alt={match.team1name}
                                            className='rounded-full object-contain border'
                                            width='40'
                                            height='40'
                                            src={getTeamImage(match.team1logo, match.team1name)}
                                          />
                                          <div>{match.team1name}</div>
                                        </div>
                                        {(match.team1score != null || match.team2score != null) && (
                                          <>
                                            <div
                                              className={classNames({
                                                'pr-5 font-medium': match.team1score != null,
                                                'text-main-alt': match.team1score > match.team2score,
                                                'text-red-500': match.team1score < match.team2score,
                                                'text-neutral-400':
                                                  match.team1score !== null && match.team1score === match.team2score,
                                              })}
                                            >
                                              {match.team1score}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      <div className='flex flex-row justify-between items-center'>
                                        <div className='flex flex-row space-x-4 text-lg font-semibold text-black items-center text-center'>
                                          <img
                                            alt={match.team2name}
                                            className='rounded-full object-contain border'
                                            width='40'
                                            height='40'
                                            src={getTeamImage(match.team2logo, match.team2name)}
                                          />
                                          <div>{match.team2name}</div>
                                        </div>
                                        {(match.team1score != null || match.team2score != null) && (
                                          <>
                                            <div
                                              className={classNames({
                                                'pr-5 font-medium': match.team2score != null,
                                                'text-main-alt': match.team2score > match.team1score,
                                                'text-red-500': match.team2score < match.team1score,
                                                'text-neutral-400':
                                                  match.team1score !== null && match.team1score === match.team2score,
                                              })}
                                            >
                                              {match.team2score}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <div className='w-4/12'>
                                      <div className='flex flex-col items-center relative space-y-2'>
                                        {match.matchday !== '' && (
                                          <div className='absolutex -bottom-8 border text-[10px] tracking-wide text-neutral-400 font-medium bg-white rounded-full p-1 px-3'>
                                            Journée {match.matchday}
                                          </div>
                                        )}
                                        <div className='flex flex-col text-center text-xs justify-center font-medium text-neutral-600 tracking-wide space-y-2'>
                                          <div>{format(new Date(match.date), 'do MMM yyyy', { locale: fr })}</div>
                                          <div>{match.time}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  ) : (
                    <div className='text-center w-full py-12 text-neutral-400'>No Matches Found</div>
                  )}
                </>
              )}
              {tab === 'Effectif' && (
                <>
                  {sLoading ? (
                    <div className='flex justify-center w-full py-12'>
                      <Spinner color='#CCCCCC' size={24} />
                    </div>
                  ) : (
                    <>
                      {userToken.type == 'Admin' &&
                        (toEdit !== '' ? (
                          <button onClick={() => handleCancelUpdate()} className='btn-cancel'>
                            Cancel
                          </button>
                        ) : (
                          <button onClick={() => setshowForm(!showForm)} className='btn-main-inverse'>
                            New Player <FiPlus />
                          </button>
                        ))}
                      {showForm && (
                        <section>
                          <form onSubmit={handlePlayersSubmit} method='post' className='flex flex-wrap items-end'>
                            <div className='w-5/12'>
                              <div className='p-2'>
                                <label htmlFor='name'>Name</label>
                                <input
                                  id='name'
                                  name='name'
                                  required
                                  type='text'
                                  className='form-control'
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className='w-4/12'>
                              <div className='p-2'>
                                <label htmlFor='position'>Position</label>
                                <select
                                  id='position'
                                  name='position'
                                  className='form-control'
                                  value={position}
                                  onChange={(e) => setPosition(e.target.value)}
                                  required
                                >
                                  <option value='' selected disabled>
                                    Select
                                  </option>
                                  <option value='Attaquant'>Attaquant</option>
                                  <option value='Milieu'>Milieu</option>
                                  <option value='Défenseur'>Défenseur</option>
                                  <option value='Gardien'>Gardien</option>
                                </select>
                              </div>
                            </div>
                            <div className='w-3/12'>
                              <div className='p-2'>
                                <button
                                  type='submit'
                                  className='btn-submit justify-center w-full'
                                  disabled={formLoading}
                                >
                                  <span>Submit</span>
                                  <span>
                                    {formLoading === false ? (
                                      <HiArrowNarrowRight />
                                    ) : (
                                      <Spinner size={14} className='ml-2' animating={formLoading} />
                                    )}
                                  </span>
                                </button>
                              </div>
                            </div>
                          </form>
                        </section>
                      )}

                      <Transition.Root show={modalopen} as={Fragment}>
                        <Dialog as='div' className='relative z-50' onClose={closeModal}>
                          <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0'
                            enterTo='opacity-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                          >
                            <div className='fixed inset-0 hidden bg-gray-50 bg-opacity-75 blur-xl transition-opacity md:block' />
                          </Transition.Child>

                          <div className='fixed inset-0 z-50 overflow-y-auto'>
                            <div className='flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4'>
                              <Transition.Child
                                as={Fragment}
                                enter='ease-out duration-300'
                                enterFrom='opacity-0 translate-y-4 md:translate-y-0 md:scale-95'
                                enterTo='opacity-100 translate-y-0 md:scale-100'
                                leave='ease-in duration-200'
                                leaveFrom='opacity-100 translate-y-0 md:scale-100'
                                leaveTo='opacity-0 translate-y-4 md:translate-y-0 md:scale-95'
                              >
                                <Dialog.Panel className='flex w-full transform text-left text-base transition md:my-8 md:max-w-lg md:px-4 lg:max-w-lg'>
                                  <div className='relative w-full items-center overflow-hidden bg-neutral-800 rounded-lg text-white shadow-2xl p-8'>
                                    <div className='w-full space-y-2'>
                                      <h5 className='text-xl font-medium'>Switch Player's Team</h5>
                                      <div
                                        className={`p-3 rounded-lg bg-neutral-50 hover:bg-neutral-100 border transition-colors relative cursor-pointer`}
                                      >
                                        <div className='flex flex-row space-x-4 items-center'>
                                          <div
                                            className={`w-12 h-12 rounded-md ${
                                              colorsarr[positionarr.indexOf(sposition)]
                                            }`}
                                          ></div>
                                          <div>
                                            <div className='text-neutral-500 font-medium tracking-wide uppercase text-xs'>
                                              {sposition}
                                            </div>
                                            <div className='heading text-xl tracking-wide font-semibold text-black'>
                                              {sname}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className={`p-3 rounded-lg bg-neutral-50 hover:bg-neutral-100 border transition-colors relative cursor-pointer`}
                                      >
                                        <div className='flex flex-row space-x-4 items-center'>
                                          <div className={`w-12 h-12 rounded-md bg-main-alt`}></div>
                                          <div className='flex-1'>
                                            {/* <div className="text-neutral-500 font-medium tracking-wide uppercase text-xs">NEW TEAM</div> */}
                                            <select
                                              id='team'
                                              name='team'
                                              className='form-control !py-3.5 w-full'
                                              value={steam}
                                              onChange={(e) => setsTeam(e.target.value)}
                                              required
                                            >
                                              <option disabled selected value=''>
                                                Select New Team
                                              </option>
                                              {teams.map((team) => (
                                                <option key={team.id} value={team.id}>
                                                  {team.compname} - {team.name}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <button
                                        type='button'
                                        className='absolute top-3 right-3 text-gray-400 hover:text-gray-500'
                                        onClick={() => closeModal()}
                                      >
                                        <span className='sr-only'>Close</span>
                                        <RxCross2 className='h-5 w-5' aria-hidden='true' />
                                      </button>
                                    </div>
                                    <div className='pt-7 mb-3 flex justify-end space-x-2'>
                                      <button disabled={sLoading} className='btn-cancel' onClick={() => closeModal()}>
                                        No, Cancel
                                      </button>
                                      <button
                                        disabled={sLoading}
                                        className='btn-submit !px-6'
                                        onClick={() => switchPlayer()}
                                      >
                                        {sLoading === false ? 'Yes, Switch' : <Spinner animating={sLoading} />}
                                      </button>
                                    </div>
                                  </div>
                                </Dialog.Panel>
                              </Transition.Child>
                            </div>
                          </div>
                        </Dialog>
                      </Transition.Root>

                      {players.length > 0 ? (
                        <>
                          <div className='grid grid-cols-2 lg:grid-cols-3 gap-5 pt-5'>
                            {players.map((player, index) => {
                              return (
                                <div key={index}>
                                  <div
                                    className={`p-3 rounded-lg bg-neutral-50 hover:bg-neutral-100 border transition-colors relative cursor-pointer`}
                                  >
                                    <div className='flex flex-row space-x-4 items-center'>
                                      <div
                                        className={`w-12 h-12 rounded-md ${
                                          colorsarr[positionarr.indexOf(player.position)]
                                        }`}
                                      ></div>
                                      <div>
                                        <div className='text-neutral-500 font-medium tracking-wide uppercase text-xs'>
                                          {player.position}
                                        </div>
                                        <div className='heading text-xl tracking-wide font-semibold'>{player.name}</div>
                                      </div>
                                    </div>
                                    {userToken.type == 'Admin' && (
                                      <div className='absolute right-0 top-0 h-full grid grid-rows-3'>
                                        <button
                                          className='flex-1 p-1 bg-white rounded-tr border'
                                          onClick={() => handlePlayersEdit(player.playerid)}
                                        >
                                          <Tooltip text='edit' customClass='!bg-blue-400'>
                                            <FiEdit className='text-blue-500 text-xs' />
                                          </Tooltip>
                                        </button>
                                        <button
                                          className='flex-1 p-1 bg-white border'
                                          onClick={() =>
                                            handlePlayerSwitch(player.name, player.position, player.playerid)
                                          }
                                        >
                                          <Tooltip text='switch' customClass='!bg-neutral-400'>
                                            <TbSwitchHorizontal className='text-neutral-500 text-xs' />
                                          </Tooltip>
                                        </button>
                                        <button
                                          className='flex-1 p-1 bg-white rounded-br border'
                                          onClick={() => handlePlayersDelete(player.playerid)}
                                        >
                                          <Tooltip text='delete' customClass='!bg-red-400'>
                                            <RiDeleteBinLine className='text-red-500 text-xs' />
                                          </Tooltip>
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </>
                      ) : (
                        <div className='text-center w-full py-12 text-neutral-400'>No Players Found</div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </section>
        </>
      ) : (
        <div className='flex justify-center w-full py-12'>
          <Spinner color='#CCCCCC' size={24} />
        </div>
      )}
    </AppLayout>
  )
}

export default TeamDetails
