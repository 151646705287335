import AppLayout from '../layouts/AppLayout'
import React, { Fragment, useEffect, useState, useContext, useMemo } from 'react'
import { AppContext } from '../contexts/AppContext'
import { Link, createMemoryRouter, useNavigate, useParams } from 'react-router-dom'
import { Dialog, Transition, Switch, Listbox } from '@headlessui/react'
import { RxCross2, RxChevronDown, RxPlusCircled } from 'react-icons/rx'
import Bounce from 'react-activity/dist/Bounce'
import 'react-activity/dist/Bounce.css'
import Spinner from 'react-activity/dist/Spinner'
import 'react-activity/dist/Spinner.css'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { FaCheck } from 'react-icons/fa6'
import { FaTimes } from 'react-icons/fa'

const divisions = [
  {
    title: 'Ligue 1',
    value: 1,
  },
  {
    title: 'Ligue 2',
    value: 2,
  },
]

const positions = ['Attaquant', 'Milieu', 'Défenseur', 'Gardien']

const PlayerForm = ({ onSubmit }) => {
  const [name, setName] = useState('')
  const [position, setPosition] = useState(positions[0])

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit({ name, position })
  }

  return (
    <div className='flex justify-between items-center'>
      <div className='p-2 w-full'>
        <label htmlFor='name'>Player Name</label>
        <input
          id='name'
          name='name'
          required
          type='text'
          className='form-control'
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      <div className='p-2 w-full'>
        <Listbox value={position} onChange={setPosition}>
          <Listbox.Label>Position</Listbox.Label>
          <div className='relative mt-1'>
            <Listbox.Button className='form-control relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'>
              <span className='block truncate'>{position}</span>
              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                <RxChevronDown className='h-5 w-5 text-gray-400' aria-hidden='true' />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm'>
                {positions.map((position, index) => (
                  <Listbox.Option
                    key={position}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                      }`
                    }
                    value={position}
                  >
                    {position}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>

      <button onClick={handleSubmit} className='btn-submit mt-5'>
        <RxPlusCircled />
      </button>
    </div>
  )
}

const CreateTeam = () => {
  const { teamId } = useParams()
  const [name, setName] = useState('')
  const [status, setStatus] = useState('Active')
  const [competition, setCompetition] = useState(1)
  const [file, setFile] = useState(null)
  const [filePreviewURL, setFilePreviewURL] = useState('')
  const [players, setPlayers] = useState([])
  const [error, setError] = useState('')

  const { createTeam, isLoading } = useContext(AppContext)

  const filePreview = useMemo(() => {
    if (!file) return null

    setFilePreviewURL(URL.createObjectURL(file))

    return filePreviewURL
  }, [file])

  const selectedCompeitionTitle = useMemo(() => {
    const div = divisions.find((d) => d.value === competition)
    return div?.title
  }, [competition])

  //   useEffect(() => {
  // const fetchMember = async () => {
  //   const response = await getmember(teamId);
  //   if (response) {
  //     setMember(response[0]);
  //     setName(response[0].name);
  //     setStatus(response[0].email);
  //     setStatus(response[0].email);
  //     setPassword(response[0].password);
  //     setConfirmpassword(response[0].confirmpassword);
  //   }
  // };

  // if (teamId) {
  //   fetchMember();
  // }
  //   }, [teamId]);

  const navigate = useNavigate()

  const handleFileUpload = (event) => {
    const [uploadedFile] = event.target.files
    setFile(uploadedFile)
  }

  const handleAddPlayer = (player) => {
    setPlayers((prev) => {
      return [...prev, player]
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (isLoading) return

    try {
      setError('')
      let response = ''

      if (!name || !file || !competition) {
        setError('All fields are required')
        return
      }
      if (teamId) {
        // response = await updatemember({ name, email, status, teamId });
      } else {
        response = await createTeam({ name, status, competition, file, players })
        if (response === 'success') {
          navigate('/teams')
        }
      }
    } catch (error) {
      setError('Request failed: ' + error)
    }
  }

  return (
    <AppLayout pageName={`${teamId ? 'Update' : 'Create'} Team`}>
      <section>
        <form
          onSubmit={handleSubmit}
          method='post'
          encType='multipart/form-data'
          className={`grid grid-cols-1 sm:grid-cols-2 gap-4`}
        >
          <div className='mb-2'>
            <div className='p-2'>
              <label htmlFor='name'>Team Logo</label>
              {!filePreview ? (
                <input
                  id='logo'
                  name='logo'
                  required
                  type='file'
                  className='form-control'
                  onChange={handleFileUpload}
                />
              ) : (
                <div className='w-[200px] h-[auto] relative'>
                  <div
                    onClick={() => setFile(null)}
                    className='text-cancel absolute opacity-0 hover:opacity-50 w-full h-full bg-blue-200 flex justify-center items-center cursor-pointer'
                  >
                    remove
                  </div>
                  <img src={filePreview} className='rounded-xl' alt='' />
                </div>
              )}
            </div>
            <div className='p-2'>
              <label htmlFor='name'>Team Name</label>
              <input
                id='name'
                name='name'
                required
                type='text'
                className='form-control'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className='p-2'>
              <Listbox value={competition} onChange={setCompetition}>
                <Listbox.Label>Division</Listbox.Label>
                <div className='relative mt-1'>
                  <Listbox.Button className='form-control relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'>
                    <span className='block truncate'>{selectedCompeitionTitle}</span>
                    <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                      <RxChevronDown className='h-5 w-5 text-gray-400' aria-hidden='true' />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave='transition ease-in duration-100'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <Listbox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-10'>
                      {divisions.map((division, index) => (
                        <Listbox.Option
                          key={division.value}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                            }`
                          }
                          value={division.value}
                        >
                          {division.title}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
            <div className='p-2'>
              <Listbox value={status} onChange={setStatus}>
                <Listbox.Label>Status</Listbox.Label>
                <div className='relative mt-1'>
                  <Listbox.Button className='form-control relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'>
                    <span className='block truncate'>{status}</span>
                    <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                      <RxChevronDown className='h-5 w-5 text-gray-400' aria-hidden='true' />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave='transition ease-in duration-100'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <Listbox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-10'>
                      {['Active', 'Inactive'].map((status, index) => (
                        <Listbox.Option
                          key={status}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                            }`
                          }
                          value={status}
                        >
                          {status}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>

            <div className='p-2'>
              <PlayerForm onSubmit={handleAddPlayer} />
            </div>

            <div className='px-2'>
              {error !== '' ? (
                <div className='text-left text-red-500 font-semibold text-md mb-2 tracking-wide'>{error}</div>
              ) : (
                <div className='py-4'></div>
              )}

              <div className='flex space-x-3'>
                <button type='submit' className='btn-submit' disabled={isLoading}>
                  <span>{teamId ? 'Sauvegarder les modifications' : 'Submit'}</span>
                  <span>
                    {isLoading === false ? (
                      <HiArrowNarrowRight />
                    ) : (
                      <Spinner size={14} className='ml-2' animating={isLoading} />
                    )}
                  </span>
                </button>
                {teamId && (
                  <Link to={'/all-members'} className='btn-cancel' disabled={isLoading}>
                    <span>Cancel</span>
                  </Link>
                )}
              </div>
            </div>
          </div>

          <div className='mb-2'>
            <table className='w-full text-sm text-center text-gray-800'>
              <thead className='text-xs text-white uppercase bg-main'>
                <tr>
                  <th scope='col' className='px-6 py-4 text-left'>
                    Player Name
                  </th>
                  <th scope='col' className='px-6 py-4'>
                    Player Position
                  </th>
                </tr>
              </thead>
              <tbody>
                {players.map((player, index) => {
                  return (
                    <tr key={player.name} className='bg-white border-b-4 border-blue-100'>
                      <td
                        scope='row'
                        className='px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left'
                      >
                        {player.name}
                      </td>
                      <td className='px-6 py-4 text-lg'>{player.position}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </form>
      </section>
    </AppLayout>
  )
}

export default CreateTeam
