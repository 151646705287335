import React, { useContext, useState, useEffect } from 'react'
import AppLayout from '../layouts/AppLayout'
import DataTable from '../components/DataTable'
import { AppContext } from '../contexts/AppContext'
import { Link } from 'react-router-dom'
import Spinner from 'react-activity/dist/Spinner'
import 'react-activity/dist/Spinner.css'
import Swal from 'sweetalert2'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { getTeamImage } from '../utils/team'

const AllMatches = () => {
  const { getmatches, deletematch, isLoading } = useContext(AppContext)

  const [matches, setMatches] = useState([])
  const [statusFilter, setStatusFilter] = useState('')

  const handleDelete = async (match) => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: 'Are you sure to delete?',
          text: "You won't be able to undo this action",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Delete',
        })

        if (result.isConfirmed) {
          let response = await deletematch(match)
          if (response === 'success') {
            fetchMatches()
          }
        }
      } catch (error) {
        Swal.fire('An Error Occurred', error, 'error')
      }
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Team 1',
        accessor: 'team1name',
        Cell: ({ row }) => {
          return (
            <Link to={`/teams/${row.original.team1name.toLowerCase().replaceAll(' ', '')}/${row.original.team1}`}>
              <div className='flex space-x-2 text-base font-medium items-center text-neutral-800'>
                <img width='25' height='25' src={getTeamImage(row.original.team1logo, row.original.team1name)} />{' '}
                <span>{row.original.team1name}</span>
              </div>
            </Link>
          )
        },
      },
      {
        Header: 'Score',
        accessor: 'score',
        Cell: ({ row }) => {
          const { team1score, team2score } = row.original
          return (
            <Link to={`/match-details/${row.original.matchid}`}>
              <div className='flex justify-between items-center w-full'>
                {team1score} - {team2score}
              </div>
            </Link>
          )
        },
      },
      {
        Header: 'Team 2',
        accessor: 'team2name',
        Cell: ({ row }) => {
          return (
            <Link to={`/teams/${row.original.team1name.toLowerCase().replaceAll(' ', '')}/${row.original.team2}`}>
              <div className='flex space-x-2 text-base font-medium items-center text-neutral-800'>
                <img width='25' height='25' src={getTeamImage(row.original.team2logo, row.original.team2name)} />{' '}
                <span>{row.original.team2name}</span>
              </div>
            </Link>
          )
        },
      },
      { Header: 'Competition', accessor: 'compname' },
      { Header: 'Journée', accessor: 'matchday' },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ row }) => format(new Date(row.original.date), 'EEE, do MMM yyyy', { locale: fr }),
      },
      { Header: 'Time', accessor: 'time' },
      { Header: 'Status', accessor: 'status' },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          <div className='flex items-center space-x-2'>
            <Link
              className='block btn-main-alt-inv !py-1 !px-3 !rounded-sm !text-[12px]'
              to={`/match-details/${row.original.matchid}`}
            >
              View Details
            </Link>
            {row.original.status !== 'Completed' && (
              <Link className='text-edit' to={`/edit-match/${row.original.matchid}`}>
                Edit
              </Link>
            )}
            <button className='text-delete' onClick={() => handleDelete(row.original.matchid)}>
              Delete
            </button>
          </div>
        ),
      },
      { Header: 'Added At', accessor: 'addedatdate' },
    ],
    []
  )

  const fetchMatches = async () => {
    const response = await getmatches(statusFilter)
    setMatches(response)
  }

  useEffect(() => {
    fetchMatches()
  }, [statusFilter])

  return (
    <AppLayout pageName='All Matches'>
      <section>
        <div className='flex justify-between flex-wrap'>
          <div className='mb-4'>
            <label htmlFor='statusFilter' className='mb-1'>
              Filter by Status
            </label>
            <select
              id='statusFilter'
              name='statusFilter'
              className='form-control'
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value=''>All</option>
              <option value='Scheduled'>Scheduled</option>
              <option value='Postponed'>Postponed</option>
              <option value='Suspended'>Suspended</option>
            </select>
          </div>
          <div className='mb-4'>
            <Link className='inline-block' to='/add-match'>
              <button className='btn-main-inverse'>
                Add new Match <HiArrowNarrowRight />
              </button>
            </Link>
          </div>
        </div>

        {isLoading ? (
          <div className='flex justify-center w-full py-12'>
            <Spinner color='#CCCCCC' size={24} />
          </div>
        ) : (
          <DataTable data={matches} columns={columns} />
        )}
      </section>
    </AppLayout>
  )
}

export default AllMatches
