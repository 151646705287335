import AppLayout from "../layouts/AppLayout";
import React, { Fragment, useEffect, useState, useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import { FaCheck } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";

const AddAdmin = () => {
  const { userId } = useParams();

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [admin, setAdmin] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [modalopen, setModalopen] = useState(false);
  const [reviewaction, setReviewaction] = useState("");

  const { getadmin, reviewadmin, addadmin, updateadmin, isLoading } = useContext(AppContext);

  useEffect(() => {
    const fetchAdmin = async () => {
      const response = await getadmin(userId);
      if (response) {
        setAdmin(response[0]);
        setName(response[0].name);
        setEmail(response[0].email);
        setPassword(response[0].password);
        setConfirmpassword(response[0].confirmpassword);
        setStatus(response[0].status);
      }
    };

    if (userId) {
        if(userId !== userToken.userid){
            fetchAdmin();
        }
        else{
            navigate("/all-admins");
        }
    }
  }, [userId]);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading === false) {
      try {
        if (name !== "" && email !== "") {
          setError("");
          let response = "";
          if (userId) {
            response = await updateadmin({ name, email, status, userId });
          } else {
            if (password.length < 6) {
              setError("Password must have at least 6 characters");
              return;
            } else {
              response = await addadmin({ name, email, password, confirmpassword });
            }
          }
          if (response === "success") {
            navigate("/all-admins");
          }
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("Request failed: " + error);
      }
    }
  };

  const reviewAdmin = async () => {
    const response = await reviewadmin({ userid: userId, reviewaction });
    if (response === "success") {
      setModalopen(false);
      setReviewaction("");
      navigate("/all-admins");
    }
  };

  const prepareaction = (action) => {
    setModalopen(true);
    setReviewaction(action);
  };

  const unprepareaction = () => {
    setModalopen(false);
    setReviewaction("");
  };

  return (
    <AppLayout pageName={`${userId ? "Update" : "Add New"} Admin`}>
      <section>
        {status === "Under Review" ? (
          <>
            <div className="flex items-center p-4 mb-5 text-sm text-red-500 rounded-lg bg-red-100" role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Info</span>
              <div>
                <span className="font-medium">Under Review!</span> This admin needs to be reviewed by the admin.
              </div>
            </div>

            <div className="flex space-x-4 mb-5">
              <button onClick={() => prepareaction("Approved")} type="button" className="btn-submit !bg-green-600  !border-green-600" disabled={isLoading}>
                <span>Approve</span>
                <span>{isLoading === false ? <FaCheck /> : <Bounce animating={isLoading} />}</span>
              </button>
              <button onClick={() => prepareaction("Rejected")} type="button" className="btn-submit !bg-red-500  !border-red-500" disabled={isLoading}>
                <span>Reject</span>
                <span>{isLoading === false ? <FaTimes /> : <Bounce animating={isLoading} />}</span>
              </button>
            </div>

            <Transition.Root show={modalopen} as={Fragment}>
              <Dialog as="div" className="relative z-50" onClose={unprepareaction}>
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                  <div className="fixed inset-0 hidden bg-gray-50 bg-opacity-75 blur-xl transition-opacity md:block" />
                </Transition.Child>

                <div className="fixed inset-0 z-50 overflow-y-auto">
                  <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95" enterTo="opacity-100 translate-y-0 md:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 md:scale-100" leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95">
                      <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-lg md:px-4 lg:max-w-lg">
                        <div className="relative w-full items-center overflow-hidden bg-neutral-800 rounded-lg text-white shadow-2xl p-8">
                          <div className="w-full">
                            <h5 className="text-xl font-medium mb-2">Are you sure you want to {reviewaction} this admin?</h5>
                            <h5 className="text-md text-neutral-400 font-light">This action cannot be undone</h5>
                            <button type="button" className="absolute top-3 right-3 text-gray-400 hover:text-gray-500" onClick={() => unprepareaction()}>
                              <span className="sr-only">Close</span>
                              <RxCross2 className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                          {reviewaction !== "" ? (
                            <div className="px-3 pt-7 mb-3 flex justify-end space-x-2">
                              <button disabled={isLoading} className="btn-main-inverse" onClick={() => unprepareaction()}>
                                No, Cancel
                              </button>
                              <button disabled={isLoading} className="btn-main !px-6" onClick={() => reviewAdmin()}>
                                {isLoading === false ? "Yes, Proceed" : <Spinner animating={isLoading} />}
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit} method="post" encType="multipart/form-data" className={`flex flex-row flex-wrap w-full ${status === "Under Review" ? "pointer-events-none" : ""}`}>
          <div className="w-full lg:w-2/4 mb-2">
            <div className="p-2">
              <label htmlFor="name">Name</label>
              <input id="name" name="name" required type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="p-2">
              <label htmlFor="email">Adresse e-mail</label>
              <input id="email" name="email" required type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} maxLength={255} />
            </div>
            {!userId ? (
              <div className="p-2">
                <div className="flex flex-row items-end space-x-5">
                  <div className="w-1/2">
                    <div>
                      <label htmlFor="password">Password</label>
                      <input id="password" name="password" required type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div>
                      <label htmlFor="name">Confirm Password</label>
                      <input id="confirmpassword" name="confirmpassword" required type="password" className="form-control" value={confirmpassword} onChange={(e) => setConfirmpassword(e.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="w-full text-xs text-neutral-500 tracking-wide font-medium mt-2">The password must have at least 6 characters</div>
              </div>
            ) : (
              <div className="p-2">
                <label htmlFor="email">Account Status</label>
                <select id="status" name="status" className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
            )}
            <div className="px-2">
              {error !== "" ? <div className="text-left text-red-500 font-semibold text-md mb-2 tracking-wide">{error}</div> : <div className="py-4"></div>}
              {status === "Under Review" ? (
                ""
              ) : (
                <>
                  <div className="flex space-x-3">
                    <button type="submit" className="btn-submit" disabled={isLoading}>
                      <span>{userId ? "Sauvegarder les modifications" : "Submit"}</span>
                      <span>{isLoading === false ? <HiArrowNarrowRight /> : <Spinner size={14} className="ml-2" animating={isLoading} />}</span>
                    </button>
                    {userId && (
                      <Link to={"/all-admins"} className="btn-cancel" disabled={isLoading}>
                        <span>Cancel</span>
                      </Link>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
      </section>
    </AppLayout>
  );
};

export default AddAdmin;
