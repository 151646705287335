import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { RxDashboard } from 'react-icons/rx'
import { FiUsers, FiUser, FiLogOut } from 'react-icons/fi'
import { FaBarsStaggered } from 'react-icons/fa6'
import { LuLayoutList } from 'react-icons/lu'
import { PiPassword } from 'react-icons/pi'
import { MdDoubleArrow } from 'react-icons/md'
import { AppContext } from '../contexts/AppContext'
import { PiUserCircleLight } from 'react-icons/pi'
import { TbPlayFootball } from 'react-icons/tb'
import { IoSettingsOutline } from 'react-icons/io5'
import { MdOutlineAnalytics } from 'react-icons/md'
import { LuSettings2 } from 'react-icons/lu'
import { HiOutlineDocumentReport } from 'react-icons/hi'
import { AuthContext } from '../contexts/AuthContext'

const AppLayout = ({ children, pageName }) => {
  const navigate = useNavigate()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const sidebarRef = useRef(null)
  const { userToken, logout } = useContext(AppContext)
  // const { userToken } = useContext(AuthContext);

  useEffect(() => {
    if (userToken === null) {
      navigate('/')
    }
  }, [])

  const handleLogout = async () => {
    const response = await logout()
    if (response === 'success') {
      navigate('/')
    }
  }

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  //   // Callback ref to ensure we get the latest node
  //   const setSidebarRef = useCallback((node) => {
  //     sidebarRef.current = node;
  //   }, []);

  //   useEffect(() => {
  //     // Function to check if clicked outside of sidebar
  //     const handleClickOutside = (event) => {
  //       alert("test");
  //       if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
  //         setIsSidebarOpen(false);
  //       }
  //     };

  //     // Add event listener
  //     document.addEventListener("mousedown", handleClickOutside);

  //     // Clean up
  //     return () => {
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   }, [sidebarRef]);

  return (
    <>
      {userToken !== null ? (
        <div className={`d-flex ${isSidebarOpen ? 'toggled' : ''}`} id='wrapper' ref={sidebarRef}>
          <div id='sidebar-wrapper' className='my-2 top-0'>
            <div className='sidebar-heading'>
              <img src='/favi.png' className='w-9 invert logo-sm' />
              <img src='/logo.png' className='p-0 invert logo' />
              <button className='btn' onClick={handleSidebarToggle} id='menu-toggle'>
                <MdDoubleArrow color='#FFF' size={42} />
              </button>
            </div>
            <div className='list-group list-group-flush pt-1.5 pb-3'>
              <div className='w-100'>
                <div className='px-2 d-flex'>
                  <Link to='/dashboard' className={`nav-main ${pageName === 'Accueil' ? 'active-link' : ''}`}>
                    <div className='nav-hide'>
                      <RxDashboard /> Accueil
                    </div>
                    <div className='nav-icon'>
                      <RxDashboard />
                    </div>
                  </Link>
                  {userToken.type === 'Admin' ? (
                    <>
                      <Link to='/overview' className={`nav-main ${pageName === 'Aperçu' ? 'active-link' : ''}`}>
                        <div className='nav-hide'>
                          <MdOutlineAnalytics /> Aperçu
                        </div>
                        <div className='nav-icon'>
                          <MdOutlineAnalytics />
                        </div>
                      </Link>
                      <Link
                        to='/report'
                        className={`nav-main ${pageName === 'Rapports Journées' ? 'active-link' : ''}`}
                      >
                        <div className='nav-hide'>
                          <HiOutlineDocumentReport /> Rapports Journées
                        </div>
                        <div className='nav-icon'>
                          <HiOutlineDocumentReport />
                        </div>
                      </Link>
                      <Link
                        to='/all-members'
                        className={`nav-main ${
                          pageName === 'All Members' || pageName === 'Add New Member' || pageName === 'Update Member'
                            ? 'active-link'
                            : ''
                        }`}
                      >
                        <div className='nav-hide'>
                          <FiUsers /> Members
                        </div>
                        <div className='nav-icon'>
                          <FiUsers />
                        </div>
                      </Link>
                      <Link
                        to='/all-matches'
                        className={`nav-main ${
                          pageName === 'All Matches' || pageName === 'Match Details' || pageName === 'Add New Match'
                            ? 'active-link'
                            : ''
                        }`}
                      >
                        <div className='nav-hide'>
                          <LuLayoutList /> Matches
                        </div>
                        <div className='nav-icon'>
                          <LuLayoutList />
                        </div>
                      </Link>
                      <Link
                        to='/teams'
                        className={`nav-main ${
                          pageName === 'Toutes les équipes' || pageName === 'Team Details' ? 'active-link' : ''
                        }`}
                      >
                        <div className='nav-hide'>
                          <TbPlayFootball /> Toutes les équipes
                        </div>
                        <div className='nav-icon'>
                          <TbPlayFootball />
                        </div>
                      </Link>
                      <Link
                        to='/all-admins'
                        className={`nav-main ${
                          pageName === 'All Admins' || pageName === 'Add New Admin' || pageName === 'Update Admin'
                            ? 'active-link'
                            : ''
                        }`}
                      >
                        <div className='nav-hide'>
                          <FiUsers /> Admins
                        </div>
                        <div className='nav-icon'>
                          <FiUsers />
                        </div>
                      </Link>
                    </>
                  ) : (
                    ''
                  )}

                  {userToken.type === 'Member' && (
                    <>
                      <Link
                        to='/matches/Ligue 1'
                        className={`nav-main ${
                          pageName === 'Ligue 1' || pageName === 'Match Details' ? 'active-link' : ''
                        }`}
                      >
                        <div className='nav-hide'>
                          <img width={18} height={18} className='mr-2.5 rounded' src='/images/teams/ligue1-sm.png' />{' '}
                          Ligue 1
                        </div>
                        <div className='nav-icon'>
                          <img width={18} height={18} className='mr-2.5 rounded' src='/images/teams/ligue1-sm.png' />
                        </div>
                      </Link>
                      <Link
                        to='/matches/Ligue 2'
                        className={`nav-main ${
                          pageName === 'Ligue 2' || pageName === 'Match Details' ? 'active-link' : ''
                        }`}
                      >
                        <div className='nav-hide'>
                          <img width={18} height={18} className='mr-2.5 rounded' src='/images/teams/ligue2-sm.png' />{' '}
                          Ligue 2
                        </div>
                        <div className='nav-icon'>
                          <img width={18} height={18} className='mr-2.5 rounded' src='/images/teams/ligue2-sm.png' />
                        </div>
                      </Link>
                      <Link to='/overview' className={`nav-main ${pageName === 'Aperçu' ? 'active-link' : ''}`}>
                        <div className='nav-hide'>
                          <MdOutlineAnalytics /> Aperçu
                        </div>
                        <div className='nav-icon'>
                          <MdOutlineAnalytics />
                        </div>
                      </Link>
                      <Link
                        to='/report'
                        className={`nav-main ${pageName === 'Rapports Journées' ? 'active-link' : ''}`}
                      >
                        <div className='nav-hide'>
                          <HiOutlineDocumentReport /> Rapports Journées
                        </div>
                        <div className='nav-icon'>
                          <HiOutlineDocumentReport />
                        </div>
                      </Link>
                      <Link
                        to='/teams'
                        className={`nav-main ${
                          pageName === 'Toutes les équipes' || pageName === 'Team Details' ? 'active-link' : ''
                        }`}
                      >
                        <div className='nav-hide'>
                          <TbPlayFootball /> Toutes les équipes
                        </div>
                        <div className='nav-icon'>
                          <TbPlayFootball />
                        </div>
                      </Link>
                    </>
                  )}
                </div>
                <div className='border-top px-2 d-flex'>
                  <br />
                  <p>
                    <i className='fas fa-minus fa-sm'></i>&nbsp; Mon Compte
                  </p>
                  <Link to='/profile' className={`nav-main ${pageName === 'Mon Compte' ? 'active-link' : ''}`}>
                    <div className='nav-hide'>
                      <FiUser /> Mon Compte
                    </div>
                    <div className='nav-icon'>
                      <FiUser />
                    </div>
                  </Link>
                  <Link
                    to='/change-password'
                    className={`nav-main ${pageName === 'Changez le mot de passe' ? 'active-link' : ''}`}
                  >
                    <div className='nav-hide'>
                      <PiPassword /> Changez le mot de passe
                    </div>
                    <div className='nav-icon'>
                      <PiPassword />
                    </div>
                  </Link>
                </div>
              </div>
              <div className='w-100'>
                <div className='border-top px-2 d-flex'>
                  <br />
                  {userToken.type === 'Admin' && (
                    <>
                      {/* <p>
                        <i className="fas fa-minus fa-sm"></i>&nbsp; Configuration
                      </p> */}
                      <Link
                        to='/edit-matchday-data'
                        className={`nav-main ${pageName === 'Matchday Data' ? 'active-link' : ''}`}
                      >
                        <div className='nav-hide'>
                          <LuSettings2 /> Matchday Data
                        </div>
                        <div className='nav-icon'>
                          <LuSettings2 />
                        </div>
                      </Link>
                      <Link
                        to='/edit-websites-links'
                        className={`nav-main ${pageName === 'Websites Links' ? 'active-link' : ''}`}
                      >
                        <div className='nav-hide'>
                          <LuSettings2 /> Websites Links
                        </div>
                        <div className='nav-icon'>
                          <LuSettings2 />
                        </div>
                      </Link>
                    </>
                  )}
                  <Link to='/' className='nav-main' onClick={handleLogout}>
                    <div className='nav-hide'>
                      <FiLogOut /> Se déconnecter
                    </div>
                    <div className='nav-icon'>
                      <FiLogOut />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div id='page-content-wrapper'>
            <div className='flex-1 p-2'>
              <nav className='navbar navbar-expand-lg bg-main flex items-center'>
                <span id='menu-toggle-sm' onClick={handleSidebarToggle}>
                  <FaBarsStaggered color='#FFF' size={22} />
                </span>
                <div className='page-head ml-3'>
                  <h4>{pageName !== '' ? pageName : ''}</h4>
                </div>
                <div className='ml-auto max-sm:hidden'>
                  <div className='btn-nav-user'>
                    <PiUserCircleLight size={44} />
                    <div>
                      <h6>{userToken.name}</h6>
                      <Link to='/' onClick={handleLogout}>
                        Se déconnecter
                      </Link>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
            <div className='mx-2 mb-2'>{children}</div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default AppLayout
