import AppLayout from '../layouts/AppLayout'
import React, { useState, useEffect, useContext } from 'react'
import { AppContext } from '../contexts/AppContext'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Spinner from 'react-activity/dist/Spinner'
import 'react-activity/dist/Spinner.css'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { IoIosArrowForward } from 'react-icons/io'
import classNames from 'classnames'
import { getTeamImage } from '../utils/team'

const MemberMatchDetails = () => {
  const { matchId, competition } = useParams()

  const navigate = useNavigate()

  const [match, setMatch] = useState('')
  const [type, setType] = useState('Live')
  const [isOpen, setIsOpen] = useState('')
  const [matchLinks, setmatchLinks] = useState([])
  const [linksLoading, setslinksLoading] = useState(false)
  const [liveCount, setliveCount] = useState(0)
  const [highlightCount, sethighlightCount] = useState(0)
  const [googleCount, setgoogleCount] = useState(0)
  const [totalSpectators, settotalSpectators] = useState(0)
  const [websitesLinks, setwebsitesLinks] = useState([])
  const [team1scorers, setTeam1scorers] = useState([])
  const [team2scorers, setTeam2scorers] = useState([])

  const { getmatch, getteamscorers, getmatchlinks, isLoading } = useContext(AppContext)

  useEffect(() => {
    if (matchId) {
      const fetchMatch = async () => {
        const response = await getmatch(matchId)
        if (response) {
          if (response[0] !== '') {
            setMatch(response[0])

            if (response[0].team1score !== '') {
              const response4 = await getteamscorers(response[0].team1, matchId)
              setTeam1scorers(response4)
            }
            if (response[0].team2score !== '') {
              const response5 = await getteamscorers(response[0].team2, matchId)
              setTeam2scorers(response5)
            }
          } else {
            navigate('/all-matches')
          }
        } else {
          navigate('/all-matches')
        }
      }

      fetchMatch()
    }
  }, [matchId, getmatch, navigate])

  const fetchMatchlinks = async (type) => {
    setslinksLoading(true)
    const response = await getmatchlinks(type, matchId)
    setmatchLinks(response.matchlinks)
    setliveCount(response.totallive)
    sethighlightCount(response.totalhighlights)
    setgoogleCount(response.totalgoogle)
    setwebsitesLinks(response.totalwebsites)
    settotalSpectators(response.totalspectators)
    setslinksLoading(false)
  }

  useEffect(() => {
    if (matchId) {
      fetchMatchlinks(type)
    }
  }, [type, matchId])

  const linkCatcollapse = async (cat) => {
    if (cat !== isOpen) {
      setIsOpen(cat)
      const response = await getmatchlinks(cat, type, matchId)
      setmatchLinks(response)
    } else {
      setIsOpen('')
    }
  }

  const handleSetType = async (t) => {
    setType(t)
    fetchMatchlinks(t)
  }

  return (
    <AppLayout pageName={`${competition === 'Ligue 1' ? 'Ligue 1' : 'Ligue 2'}`}>
      {match !== '' ? (
        <>
          <section>
            <div className='py-5'>
              <div className='flex justify-center space-x-4 mb-10'>
                <div className='flex space-x-2 font-normal tracking-wider items-center text-white p-2 rounded-full bg-main pr-3'>
                  <img
                    alt={match.compname}
                    className='rounded-full border-4 border-white'
                    width='30'
                    height='30'
                    src={`/images/teams/${match.compname.toLowerCase().replaceAll(' ', '')}-sm.png`}
                  />{' '}
                  <span>{match.compname}</span>
                </div>
                <div className='text-lg tracking-wide p-2 px-5 rounded-full bg-white border text-main-alt border-main-alt'>
                  {match.status}
                </div>
              </div>
              <div className='flex flex-row flex-wrap md:flex-nowrap justify-center space-x-0 md:space-x-24 heading'>
                <div className='flex flex-col w-6/12 md:w-auto space-y-4 md:space-y-8 text-lg md:text-3xl font-bold text-main items-center text-center order-2 md:order-1'>
                  <Link to={`/team/${match.team1name.toLowerCase().replaceAll(' ', '')}/${match.team1}`}>
                    <img
                      alt={match.team1name}
                      className='w-16 h-16 md:w-24 md:h-24'
                      src={getTeamImage(match.team1logo, match.team1name)}
                    />
                  </Link>
                  <Link to={`/team/${match.team1name.toLowerCase().replaceAll(' ', '')}/${match.team1}`}>
                    <div>{match.team1name}</div>
                  </Link>
                </div>
                <div className='flex flex-col w-full md:w-auto space-y-4 text-3xl font-bold text-black text-center justify-center order-1 md:order-2 mb-6 md:mb-0'>
                  {match.matchday !== '' && (
                    <div className='text-base tracking-wide text-neutral-400 font-light'>Journée {match.matchday}</div>
                  )}
                  <div className=''>{format(new Date(match.date), 'EEE, do MMM yyyy', { locale: fr })}</div>
                  <div className='flex justify-center'>
                    <div className='text-lg tracking-wide p-1 px-5 rounded-full bg-neutral-100 text-neutral-800 mt-1'>
                      {match.time}
                    </div>
                  </div>
                  {match.team1score != null && match.team2score != null && (
                    <>
                      <div className='flex justify-center items-top space-x-5'>
                        <div>
                          <div
                            className={classNames({
                              'rounded py-2 w-12 text-center text-white': match.team1score != null,
                              'bg-main-alt': match.team1score > match.team2score,
                              'bg-red-700': match.team1score < match.team2score,
                              'bg-neutral-500': match.team1score !== null && match.team1score === match.team2score,
                            })}
                          >
                            {match.team1score}
                          </div>
                        </div>
                        <div className='text-lg font-bold text-neutral-500 pt-2.5'>-</div>
                        <div>
                          <div
                            className={classNames({
                              'rounded py-2 w-12 text-center text-white': match.team2score != null,
                              'bg-main-alt': match.team2score > match.team1score,
                              'bg-red-600': match.team2score < match.team1score,
                              'bg-neutral-500': match.team1score !== null && match.team1score === match.team2score,
                            })}
                          >
                            {match.team2score}
                          </div>
                        </div>
                      </div>
                      <div className='flex justify-center items-top'>
                        <div className='flex-1'>
                          {match.team1score > 0 && (
                            <div className='space-y-1 text-neutral-500 text-xs text-right font-normal normal-font'>
                              {team1scorers.map((scorer, index) => {
                                return (
                                  <div className='whitespace-nowrap' key={index}>
                                    {scorer.playername}
                                  </div>
                                )
                              })}
                            </div>
                          )}
                        </div>
                        <div className='text-lg font-bold text-neutral-500 w-11'></div>
                        <div className='flex-1'>
                          {match.team2score > 0 && (
                            <div className='space-y-1 text-neutral-500 text-xs text-left font-normal normal-font'>
                              {team2scorers.map((scorer, index) => {
                                return (
                                  <div className='whitespace-nowrap' key={index}>
                                    {scorer.playername}
                                  </div>
                                )
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className='flex flex-col w-6/12 md:w-auto space-y-4 md:space-y-8 text-lg md:text-3xl font-bold text-main items-center text-center order-3 md:order-3'>
                  <Link to={`/team/${match.team2name.toLowerCase().replaceAll(' ', '')}/${match.team2}`}>
                    <img
                      alt={match.team2name}
                      className='w-16 h-16 md:w-24 md:h-24'
                      src={getTeamImage(match.team2logo, match.team2name)}
                    />
                  </Link>
                  <Link to={`/team/${match.team2name.toLowerCase().replaceAll(' ', '')}/${match.team2}`}>
                    <div>{match.team2name}</div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className='flex flex-col lg:flex-col justify-between items-center space-x-0 lg:space-x-3'>
              <div className='text-center'>
                <h2 className='text-2xl font-bold tracking-normal text-main mb-2'>Liens du match</h2>
                <div className='flex flex-row items-center space-x-3 mb-2'>
                  <div>
                    <span className='bg-blue-100 text-blue-800 text-sm font-medium px-3 py-2 rounded'>
                      Total des liens:{' '}
                      {liveCount + highlightCount + googleCount > 0
                        ? (liveCount + highlightCount + googleCount).toLocaleString()
                        : '0'}
                    </span>
                  </div>
                  <div>
                    <span className='bg-green-100 text-green-800 text-sm font-medium px-3 py-2 rounded'>
                      Nombre de spectateurs: {totalSpectators > 0 ? totalSpectators.toLocaleString() : 0}
                    </span>
                  </div>
                </div>
              </div>
              {websitesLinks && (
                <div className='flex flex-wrap flex-row items-center justify-center mb-4'>
                  {websitesLinks.map((website, index) => {
                    return (
                      <>
                        <div className='flex space-x-2 border rounded p-1 pr-2 m-2 items-center'>
                          <div>
                            <img width='40' height='40' src={`/images/icons/${website.icon}.png`} />
                          </div>
                          <div>
                            <h6 className='text-neutral-500 text-xs'>{website.name}</h6>
                            <h5 className='text-main-alt leading-none mt-0.5 font-bold'>
                              {website.name == 'Google' || website.name == 'Bing' ? googleCount : website.total}
                            </h5>
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
              )}
            </div>
            <div className='pills w-full rounded-lg p-0 text-black font-semibold flex flex-row'>
              <button
                className={`${
                  type === 'Live' ? 'bg-white border border-b-white' : 'hover:bg-neutral-50 border-b text-neutral-500'
                } rounded-t-lg w-1/3 p-2 md:p-4 flex flex-col md:flex-row justify-center items-center space-x-0 md:space-x-2`}
                onClick={() => handleSetType('Live')}
              >
                <span>Live</span>
                <span className='bg-green-100 text-green-800 text-sm font-medium px-2.5 py-0.5 rounded'>
                  {liveCount}
                </span>
              </button>
              <button
                className={`${
                  type === 'Highlight'
                    ? 'bg-white border border-b-white'
                    : 'hover:bg-neutral-50 border-b text-neutral-500'
                } rounded-t-lg w-1/3 p-2 md:p-4 flex flex-col md:flex-row justify-center items-center space-x-0 md:space-x-2`}
                onClick={() => handleSetType('Highlight')}
              >
                <span>Highlights</span>
                <span className='bg-green-100 text-green-800 text-sm font-medium px-2.5 py-0.5 rounded'>
                  {highlightCount}
                </span>
              </button>
              <button
                className={`${
                  type === 'Google' ? 'bg-white border border-b-white' : 'hover:bg-neutral-50 border-b text-neutral-500'
                } rounded-t-lg w-1/3 p-2 md:p-4 flex flex-col md:flex-row justify-center items-center space-x-0 md:space-x-2`}
                onClick={() => handleSetType('Google')}
              >
                <span>Google & Bing</span>
                <span className='bg-green-100 text-green-800 text-sm font-medium px-2.5 py-0.5 rounded'>
                  {googleCount}
                </span>
              </button>
            </div>
            <section className='space-y-6 !rounded-t-none !border-t-0'>
              {linksLoading ? (
                <div className='flex justify-center w-full py-12'>
                  <Spinner color='#CCCCCC' size={24} />
                </div>
              ) : (
                <div className='text-base font-normal text-neutral-800'>
                  {matchLinks.map((link, index) => {
                    return (
                      <>
                        <div className='flex flex-row'>
                          <div className='flex-1 truncate'>
                            <a href={link.link} target='_blank' rel='noreferrer' className='w-full'>
                              <div className='flex w-full space-x-1 py-2 px-3 truncate bg-white rounded-l items-center hover:bg-neutral-200'>
                                <IoIosArrowForward size={14} /> <span className='truncate'>{link.link}</span>
                              </div>
                            </a>
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
              )}
            </section>
          </section>
        </>
      ) : (
        <div className='flex justify-center w-full py-12'>
          <Spinner color='#CCCCCC' size={24} />
        </div>
      )}
    </AppLayout>
  )
}

export default MemberMatchDetails
