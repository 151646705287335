import React, { useContext, useState, useEffect } from 'react'
import AppLayout from '../layouts/AppLayout'
import { AppContext } from '../contexts/AppContext'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Spinner from 'react-activity/dist/Spinner'
import 'react-activity/dist/Spinner.css'
import { HiArrowNarrowRight } from 'react-icons/hi'
import '../styles/matchday.css'

const EditMatchdayData = () => {
  const { matchDay } = useParams()
  const { getmatchdaysdata, getmatchdaydata, getmatchdayclosurerates, updatematchdaydata, isLoading } =
    useContext(AppContext)

  const [matchdayData, setMatchdayData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [error, setError] = useState('')
  const [slctdCompetition, setslctdCompetition] = useState('1')
  const [matchdays, setMatchdays] = useState([])
  const [matchday, setMatchday] = useState()
  const [twitterNotified, setTwitterNotified] = useState(0)
  const [twitterSuspended, setTwitterSuspended] = useState(0)
  const [tiktokNotified, setTiktokNotified] = useState(0)
  const [tiktokSuspended, setTiktokSuspended] = useState(0)
  const [telegramNotified, setTelegramNotified] = useState(0)
  const [telegramSuspended, setTelegramSuspended] = useState(0)
  const [discordSuspended, setDiscordSuspended] = useState(0)
  const [snapchatNotified, setSnapchatNotified] = useState(0)
  const [telegramImpacted, setTelegramImpacted] = useState(0)
  const [closureData, setclosureData] = useState([])
  const [closureRates, setClosureRates] = useState({})
  const [highlightRates, setHighlightRates] = useState({})
  const [closureTimes, setClosureTimes] = useState({})
  const [highlightTimes, setHighlightTimes] = useState({})

  const fetchMatchDaysData = async () => {
    const response = await getmatchdaysdata(slctdCompetition)
    setMatchdays(response)
    console.log(response)
  }

  useEffect(() => {
    fetchMatchDaysData()
  }, [slctdCompetition])

  useEffect(() => {
    const fetchdata = async () => {
      if (matchDay) {
        const response = await getmatchdaydata(matchDay, slctdCompetition)
        setMatchdayData(response)
        if (response.length > 0) {
          setTwitterNotified(response[0].accnotifiedtwitter)
          setTwitterSuspended(response[0].accsuspendedtwitter)
          setTiktokNotified(response[0].accnotifiedtiktok)
          setTiktokSuspended(response[0].accsuspendedtiktok)
          setTelegramNotified(response[0].accnotifiedtelegram)
          setTelegramSuspended(response[0].accsuspendedtelegram)
          setDiscordSuspended(response[0].accsuspendeddiscord)
          setSnapchatNotified(response[0].accnotifiedsnapchat)
          setTelegramImpacted(response[0].membersinspactedtelegram)
        }

        const response2 = await getmatchdayclosurerates(matchDay, slctdCompetition)
        setclosureData(response2)
        setClosureRates(
          response2.reduce((acc, curr) => {
            acc[curr.id] = curr.rate
            return acc
          }, {})
        )
        setHighlightRates(
          response2.reduce((acc, curr) => {
            acc[curr.id] = curr.highlightrate || ''
            return acc
          }, {})
        )

        setClosureTimes(
          response2.reduce((acc, curr) => {
            acc[curr.id] = curr.time
            return acc
          }, {})
        )
        setHighlightTimes(
          response2.reduce((acc, curr) => {
            acc[curr.id] = curr.highlighttime || ''
            return acc
          }, {})
        )

        setIsOpen(true)
      }
    }

    if (matchDay) {
      fetchdata()
    } else {
      setIsOpen(false)
    }
  }, [matchDay])

  const navigate = useNavigate()

  const handleClosureRateChange = (name, value) => {
    setClosureRates((prevRates) => ({
      ...prevRates,
      [name]: value,
    }))
  }

  const handleHighlightRateChange = (name, value) => {
    setHighlightRates((prevRates) => ({
      ...prevRates,
      [name]: value,
    }))
  }

  const handleClosureTimeChange = (name, value) => {
    setClosureTimes((prevRates) => ({
      ...prevRates,
      [name]: value,
    }))
  }

  const handleHighlightTimeChange = (name, value) => {
    setHighlightTimes((prevRates) => ({
      ...prevRates,
      [name]: value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!isLoading) {
      try {
        setError('')
        let response = ''
        if (matchDay) {
          response = await updatematchdaydata(
            matchDay,
            slctdCompetition,
            twitterNotified,
            twitterSuspended,
            tiktokNotified,
            tiktokSuspended,
            telegramNotified,
            telegramSuspended,
            discordSuspended,
            snapchatNotified,
            telegramImpacted,
            closureRates,
            highlightRates,
            closureTimes,
            highlightTimes
          )
        }
        if (response === 'success') {
          fetchMatchDaysData()
          setIsOpen(false)
          navigate('/edit-matchday-data')
        }
      } catch (error) {
        setError('Error: ' + error)
      }
    }
  }

  const competitions = ['1', '2']

  return (
    <AppLayout pageName='Matchday Data'>
      <section>
        {matchDay ? (
          <div className='text-2xl font-bold text-main mb-2'>Update Matchday {matchDay} Data</div>
        ) : (
          <div className='flex flex-wrap flex-col lg:flex-row justify-between'>
            <div className='inline-flex rounded-md mb-3'>
              {competitions.map((competition, index) => {
                return (
                  <button
                    onClick={() => setslctdCompetition(competition)}
                    aria-current='page'
                    className={`px-4 py-2 text-sm font-medium tracking-wider ${
                      competition === slctdCompetition
                        ? 'text-white bg-main'
                        : 'text-neutral-800 bg-white border border-gray-200 hover:bg-gray-100'
                    }`}
                  >
                    {'Ligue ' + competition}
                  </button>
                )
              })}
            </div>
          </div>
        )}

        {isOpen && (
          <section className='w-full !mb-4'>
            <form onSubmit={handleSubmit} className='' method='post'>
              <div className='grid grid-cols-4 gap-2'>
                <div className='p-2'>
                  <label htmlFor='twitterNotified'>
                    Accounts Notified - <b className='text-main-alt'>Twitter</b>
                  </label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none'>
                      <img alt='twitter' src='/images/icons/x.png' className='w-6 h-6' />
                    </div>
                    <input
                      id='twitterNotified'
                      name='twitterNotified'
                      type='number'
                      className='form-control !pl-12'
                      required
                      value={twitterNotified}
                      onChange={(e) => setTwitterNotified(e.target.value)}
                    />
                  </div>
                </div>
                <div className='p-2'>
                  <label htmlFor='twitterSuspended'>
                    Accounts Suspended - <b className='text-main-alt'>Twitter</b>
                  </label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none'>
                      <img alt='twitter' src='/images/icons/x.png' className='w-6 h-6' />
                    </div>
                    <input
                      id='twitterSuspended'
                      name='twitterSuspended'
                      type='number'
                      className='form-control !pl-12'
                      required
                      value={twitterSuspended}
                      onChange={(e) => setTwitterSuspended(e.target.value)}
                    />
                  </div>
                </div>

                <div className='p-2'>
                  <label htmlFor='tiktokNotified'>
                    Accounts Notified - <b className='text-main-alt'>Tiktok</b>
                  </label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none'>
                      <img alt='twitter' src='/images/icons/tiktok.png' className='w-6 h-6' />
                    </div>
                    <input
                      id='tiktokNotified'
                      name='tiktokNotified'
                      type='number'
                      className='form-control !pl-12'
                      required
                      value={tiktokNotified}
                      onChange={(e) => setTiktokNotified(e.target.value)}
                    />
                  </div>
                </div>
                <div className='p-2'>
                  <label htmlFor='tiktokSuspended'>
                    Accounts Suspended - <b className='text-main-alt'>Tiktok</b>
                  </label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none'>
                      <img alt='twitter' src='/images/icons/tiktok.png' className='w-6 h-6' />
                    </div>
                    <input
                      id='tiktokSuspended'
                      name='tiktokSuspended'
                      type='number'
                      className='form-control !pl-12'
                      required
                      value={tiktokSuspended}
                      onChange={(e) => setTiktokSuspended(e.target.value)}
                    />
                  </div>
                </div>

                <div className='p-2'>
                  <label htmlFor='telegramNotified'>
                    Accounts Notified - <b className='text-main-alt'>Telegram</b>
                  </label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none'>
                      <img alt='twitter' src='/images/icons/telegram.png' className='w-6 h-6' />
                    </div>
                    <input
                      id='telegramNotified'
                      name='telegramNotified'
                      type='number'
                      className='form-control !pl-12'
                      required
                      value={telegramNotified}
                      onChange={(e) => setTelegramNotified(e.target.value)}
                    />
                  </div>
                </div>
                <div className='p-2'>
                  <label htmlFor='telegramSuspended'>
                    Accounts Suspended - <b className='text-main-alt'>Telegram</b>
                  </label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none'>
                      <img alt='twitter' src='/images/icons/telegram.png' className='w-6 h-6' />
                    </div>
                    <input
                      id='telegramSuspended'
                      name='telegramSuspended'
                      type='number'
                      className='form-control !pl-12'
                      required
                      value={telegramSuspended}
                      onChange={(e) => setTelegramSuspended(e.target.value)}
                    />
                  </div>
                </div>
                <div className='p-2'>
                  <label htmlFor='snapchatNotified'>
                    Accounts Notified - <b className='text-main-alt'>Snapchat</b>
                  </label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none'>
                      <img alt='twitter' src='/images/icons/snapchat.png' className='w-6 h-6' />
                    </div>
                    <input
                      id='snapchatNotified'
                      name='snapchatNotified'
                      type='number'
                      className='form-control !pl-12'
                      required
                      value={snapchatNotified}
                      onChange={(e) => setSnapchatNotified(e.target.value)}
                    />
                  </div>
                </div>
                <div className='p-2'>
                  <label htmlFor='discordSuspended'>
                    Accounts Suspended - <b className='text-main-alt'>Discord</b>
                  </label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none'>
                      <img alt='twitter' src='/images/icons/discord.png' className='w-6 h-6' />
                    </div>
                    <input
                      id='discordSuspended'
                      name='discordSuspended'
                      type='number'
                      className='form-control !pl-12'
                      required
                      value={discordSuspended}
                      onChange={(e) => setDiscordSuspended(e.target.value)}
                    />
                  </div>
                </div>
                <div className='p-2'>
                  <label htmlFor='telegramImpacted'>
                    Impacted Members - <b className='text-main-alt'>Telegram</b>
                  </label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none'>
                      <img alt='twitter' src='/images/icons/telegram.png' className='w-6 h-6' />
                    </div>
                    <input
                      id='telegramImpacted'
                      name='telegramImpacted'
                      type='number'
                      className='form-control !pl-12'
                      required
                      value={telegramImpacted}
                      onChange={(e) => setTelegramImpacted(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className='px-2 py-5'>
                <hr />
              </div>

              <div className='px-2'>
                <table className='w-full text-sm text-center text-gray-800'>
                  <thead className='text-xs text-neutral-800 uppercase bg-main-alt'>
                    <tr>
                      <th scope='col' className='px-6 py-4 text-left'>
                        Plateformes
                      </th>
                      <th scope='col' className='px-6 py-4'>
                        Taux de fermeture - Live
                      </th>
                      <th scope='col' className='px-6 py-4'>
                        Taux de fermeture - Highlight
                      </th>
                      <th scope='col' className='px-6 py-4'>
                        Moyenne de temps de fermeture - Live
                      </th>
                      <th scope='col' className='px-6 py-4'>
                        Moyenne de temps de fermeture - Highlight
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {closureData.map((website, index) => {
                      return (
                        <tr key={index} className='bg-white border-b-2 border-blue-100'>
                          <th
                            scope='row'
                            className='px-6 py-4 font-medium border-r-2 border-blue-100 whitespace-nowrap text-left'
                          >
                            <div className='flex space-x-2 items-center'>
                              <img width='20' height='20' src={`/images/icons/${website.icon}.png`} />
                              <div>
                                <h6 className='text-neutral-900 text-lg'>{website.name}</h6>
                              </div>
                            </div>
                          </th>
                          <td className='px-6 py-2 text-lg'>
                            <input
                              className='form-control !py-1'
                              min={0}
                              max={100}
                              value={closureRates[website.id]}
                              onChange={(e) => handleClosureRateChange(website.id, e.target.value)}
                            />
                          </td>
                          <td className='px-6 py-2 text-lg'>
                            <input
                              className='form-control !py-1'
                              min={0}
                              max={100}
                              value={highlightRates[website.id]}
                              onChange={(e) => handleHighlightRateChange(website.id, e.target.value)}
                            />
                          </td>
                          <td className='px-6 py-2 text-lg'>
                            <input
                              className='form-control !py-1'
                              min={0}
                              max={100}
                              value={closureTimes[website.id]}
                              onChange={(e) => handleClosureTimeChange(website.id, e.target.value)}
                            />
                          </td>
                          <td className='px-6 py-2 text-lg'>
                            <input
                              className='form-control !py-1'
                              min={0}
                              max={100}
                              value={highlightTimes[website.id]}
                              onChange={(e) => handleHighlightTimeChange(website.id, e.target.value)}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>

              <div className='px-2 pb-2'>
                {error !== '' ? (
                  <div className='text-left text-red-500 font-semibold text-md mb-2 tracking-wide'>{error}</div>
                ) : (
                  <div className='py-4'></div>
                )}
                <div className='flex space-x-3'>
                  <button type='submit' className='btn-submit' disabled={isLoading}>
                    <span>{matchDay ? 'Sauvegarder les modifications' : 'Submit'}</span>
                    <span>
                      {isLoading === false ? (
                        <HiArrowNarrowRight />
                      ) : (
                        <Spinner size={14} className='ml-2' animating={isLoading} />
                      )}
                    </span>
                  </button>
                  {matchDay && (
                    <Link to={'/edit-matchday-data'} className='btn-cancel'>
                      Cancel
                    </Link>
                  )}
                </div>
              </div>
            </form>
          </section>
        )}

        {isLoading ? (
          <div className='flex justify-center w-full py-12'>
            <Spinner color='#CCCCCC' size={24} />
          </div>
        ) : (
          <>
            <div className='matchday-table'>
              <table className='table text-center mt-3'>
                <thead>
                  <tr>
                    <th></th>
                    <th colSpan={2}>Twitter</th>
                    <th colSpan={2}>Tiktok</th>
                    <th colSpan={3}>Telegram</th>
                    <th>Discord</th>
                    <th>Snapchat</th>
                    <th></th>
                  </tr>
                  <tr>
                    <th rowSpan={2} scope='col'>
                      MatchDay
                    </th>
                    <th>Accounts Notified</th>
                    <th>Accounts Suspended</th>
                    <th>Accounts Notified</th>
                    <th>Accounts Suspended</th>
                    <th>Accounts Notified</th>
                    <th>Accounts Suspended</th>
                    <th>Impacted Members</th>
                    <th>Accounts Suspended</th>
                    <th>Accounts Notified</th>
                    <th rowSpan={2} scope='col'>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {matchdays.map((matchday, index) => {
                    return (
                      <tr key={index} className='border-t font-medium'>
                        <td>{matchday.matchDay}</td>
                        <td>{matchday.accnotifiedtwitter}</td>
                        <td>{matchday.accsuspendedtwitter}</td>
                        <td>{matchday.accnotifiedtiktok}</td>
                        <td>{matchday.accsuspendedtiktok}</td>
                        <td>{matchday.accnotifiedtelegram}</td>
                        <td>{matchday.accsuspendedtelegram}</td>
                        <td>{matchday.membersinspactedtelegram}</td>
                        <td>{matchday.accsuspendeddiscord}</td>
                        <td>{matchday.accnotifiedsnapchat}</td>
                        <td>
                          <Link className='text-edit' to={`/edit-matchday-data/${matchday.matchDay}`}>
                            Edit
                          </Link>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </section>
    </AppLayout>
  )
}

export default EditMatchdayData
