import React, { useContext, useState, useEffect } from 'react'
import AppLayout from '../layouts/AppLayout'
import { AppContext } from '../contexts/AppContext'
import { Link, useParams } from 'react-router-dom'
import Spinner from 'react-activity/dist/Spinner'
import 'react-activity/dist/Spinner.css'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { IoIosArrowForward } from 'react-icons/io'
import classNames from 'classnames'
import { getTeamImage } from '../utils/team'

const MemberMatches = () => {
  const { competition } = useParams()
  const { getdaymatches, getmatchdays, getmatches, isLoading } = useContext(AppContext)

  const [matches, setMatches] = useState([])
  const [matchday, setMatchday] = useState()
  const [matchdays, setMatchdays] = useState([])
  const [statusFilter, setStatusFilter] = useState('')
  const [matchesLoading, setmatchesLoading] = useState(false)

  // const fetchMatches = async () => {
  //   const response = await getmatches(statusFilter);
  //   setMatches(response);
  // };

  const fetchMatchDays = async () => {
    const response = await getmatchdays(competition)
    setMatchdays(response.matchdays)
    if (response.currentmatchdays.length > 0) {
      setMatchday(response.currentmatchdays[0].matchday)
      setMatches(response.currentmatchdays)
    }
  }

  const fetchMatchDaysMatches = async () => {
    setmatchesLoading(true)
    const response = await getdaymatches(matchday)
    setmatchesLoading(false)
    setMatches(response)
  }

  useEffect(() => {
    fetchMatchDays()
  }, [competition])

  useEffect(() => {
    fetchMatchDaysMatches()
  }, [matchday])

  return (
    <AppLayout pageName={`${competition === 'Ligue 1' ? 'Ligue 1' : 'Ligue 2'}`}>
      {/* 
      <section>
        <div className="flex justify-between flex-wrap">
          <div className="mb-4">
            <label htmlFor="statusFilter" className="mb-1">
              Filter by Status
            </label>
            <select id="statusFilter" name="statusFilter" className="form-control" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <option value="">All</option>
              <option value="Scheduled">Scheduled</option>
              <option value="Postponed">Postponed</option>
              <option value="Suspended">Suspended</option>
            </select>
          </div>
        </div>
      </section>
      */}
      {isLoading ? (
        <div className='flex justify-center w-full py-12'>
          <Spinner color='#CCCCCC' size={24} />
        </div>
      ) : (
        <>
          <div className='space-y-3'>
            {matchdays.map((day, index) => {
              return (
                <section className='!p-0' key={index}>
                  <div
                    className={`py-5 px-5 rounded-lg bg-white hover:bg-neutral-50 transition-colors cursor-pointer flex flex-row justify-between space-x-2 items-center ${
                      matchday === day.matchday && 'border-b'
                    }`}
                    onClick={() => setMatchday(matchday === day.matchday ? '' : day.matchday)}
                  >
                    <h5 className='text-lg leading-none font-semibold tracking-wide'>Journée {day.matchday}</h5>
                    <span className={`${matchday === day.matchday && 'rotate-90'}`}>
                      <IoIosArrowForward />
                    </span>
                  </div>
                  {matchday === day.matchday && (
                    <>
                      {matchesLoading ? (
                        <div className='flex justify-center w-full py-12'>
                          <Spinner color='#CCCCCC' size={24} />
                        </div>
                      ) : (
                        <div className='space-y-7 px-5 py-7'>
                          {matches.map((match, index) => {
                            return (
                              <div key={index}>
                                <Link to={`/matches/${competition}/${match.matchid}`}>
                                  <div className={`relative`}>
                                    <div
                                      className={`py-5 px-6 rounded-lg bg-neutral-50 hover:bg-neutral-100 border transition-colors cursor-pointer`}
                                    >
                                      <div className='flex flex-row flex-wrap md:flex-nowrap justify-center space-x-0 md:space-x-10 headingx items-center'>
                                        <div className='w-6/12 md:w-3/12 order-2 md:order-1'>
                                          <div className='flex flex-row w-full justify-end space-x-4 text-md md:text-xl font-semibold text-black items-center text-center pr-3 md:pr-0'>
                                            <div>{match.team1name}</div>
                                            <img
                                              alt={match.team1name}
                                              className='rounded-full object-contain border'
                                              width='40'
                                              height='40'
                                              src={getTeamImage(match.team1logo, match.team1name)}
                                            />
                                          </div>
                                        </div>
                                        <div className='w-6/12x w-full md:w-auto relative flex flex-col space-y-1 justify-center mb-6 lg:mb-0 order-1 md:order-2'>
                                          <div className='flex flex-col items-center'>
                                            <div className='flex text-sm flex-col items-center font-semibold text-neutral-700 tracking-wide'>
                                              <div className=''>
                                                {format(new Date(match.date), 'EEE, do MMM yyyy', { locale: fr })}
                                              </div>
                                              {/* <div className="px-3 text-neutral-300 font-light">|</div> */}
                                              <div className='text-neutral-400 font-medium'>{match.time}</div>
                                            </div>
                                          </div>
                                          {(match.team1score != null || match.team2score != null) && (
                                            <>
                                              <div className='absolutex -bottom-5 text-center mx-auto'>
                                                <div className='flex flex-row justify-center items-center space-x-3 text-[11px] font-medium'>
                                                  <div
                                                    className={classNames({
                                                      'py-0.5 rounded-sm px-1.5 text-white w-5 text-center':
                                                        match.team1score != null,
                                                      'bg-main-alt': match.team1score > match.team2score,
                                                      'bg-red-700': match.team1score < match.team2score,
                                                      'bg-neutral-500':
                                                        match.team1score !== null &&
                                                        match.team1score === match.team2score,
                                                    })}
                                                  >
                                                    {match.team1score}
                                                  </div>
                                                  <div>-</div>
                                                  <div
                                                    className={classNames({
                                                      'py-0.5 rounded-sm px-1.5 text-white w-5 text-center':
                                                        match.team2score != null,
                                                      'bg-main-alt': match.team2score > match.team1score,
                                                      'bg-red-600': match.team2score < match.team1score,
                                                      'bg-neutral-500':
                                                        match.team1score !== null &&
                                                        match.team1score === match.team2score,
                                                    })}
                                                  >
                                                    {match.team2score}
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                        <div className='w-6/12 md:w-3/12 order-3 md:order-3'>
                                          <div className='flex flex-row w-full justify-start space-x-4 text-md md:text-xl font-semibold text-black items-center text-center pl-3 md:pl-0'>
                                            <img
                                              alt={match.team2name}
                                              className='rounded-full object-contain border'
                                              width='40'
                                              height='40'
                                              src={getTeamImage(match.team2logo, match.team2name)}
                                            />
                                            <div>{match.team2name}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </>
                  )}
                </section>
              )
            })}
          </div>
        </>
      )}
    </AppLayout>
  )
}

export default MemberMatches
