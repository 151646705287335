import AppLayout from '../layouts/AppLayout'
import React, { useState, useEffect, useContext } from 'react'
import { AppContext } from '../contexts/AppContext'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Spinner from 'react-activity/dist/Spinner'
import 'react-activity/dist/Spinner.css'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { FiPlus } from 'react-icons/fi'
import { IoIosArrowForward } from 'react-icons/io'
import { RiDeleteBin5Line } from 'react-icons/ri'
import '../styles/match.css'
import classNames from 'classnames'
import Swal from 'sweetalert2'
import { getTeamImage } from '../utils/team'

const MatchDetails = () => {
  const { matchId } = useParams()

  const navigate = useNavigate()

  const [match, setMatch] = useState('')
  const [categories, setCategories] = useState([])
  const [type, setType] = useState('Live')
  const [linksInput, setlinksInput] = useState('')
  const [linksLoading, setslinksLoading] = useState(false)
  const [scoreLoading, setscoreLoading] = useState(false)
  const [showLinkForm, setshowLinkForm] = useState(false)
  const [showScoreForm, setshowScoreForm] = useState(false)
  const [linkFormLoading, setlinkFormLoading] = useState(false)
  const [linksError, setlinksError] = useState('')
  const [matchLinks, setmatchLinks] = useState([])
  const [liveCount, setliveCount] = useState(0)
  const [highlightCount, sethighlightCount] = useState(0)
  const [googleCount, setgoogleCount] = useState(0)
  const [totalSpectators, settotalSpectators] = useState(0)
  const [websitesLinks, setwebsitesLinks] = useState([])
  const [team1players, setTeam1players] = useState([])
  const [team2players, setTeam2players] = useState([])
  const [team1score, setTeam1score] = useState('')
  const [team2score, setTeam2score] = useState('')
  const [team1Selects, setTeam1Selects] = useState({})
  const [team2Selects, setTeam2Selects] = useState({})
  const [team1scorers, setTeam1scorers] = useState([])
  const [team2scorers, setTeam2scorers] = useState([])
  const [websites, setWebsite] = useState([])
  const [specwebsites, setspecWebsite] = useState([])
  const [spectatorCounts, setSpectatorCounts] = useState({})
  const [showSpecButton, setshowSpecButton] = useState(false)
  const [specFormLoading, setspecFormLoading] = useState(false)

  const {
    getmatch,
    getteamplayers,
    getteamscorers,
    getlinkcategories,
    getmatchlinks,
    getmatchwebsites,
    getspecwebsites,
    updatematchspectators,
    addmatchlinks,
    addmatchscore,
    deletematchlink,
    isLoading,
  } = useContext(AppContext)

  const fetchMatch = async () => {
    const response = await getmatch(matchId)
    if (response) {
      if (response[0] !== '') {
        setMatch(response[0])
        const response2 = await getteamplayers(response[0].team1)
        setTeam1players(response2)
        const response3 = await getteamplayers(response[0].team2)
        setTeam2players(response3)

        if (response[0].team1score !== '') {
          const response4 = await getteamscorers(response[0].team1, matchId)
          setTeam1scorers(response4)
        }
        if (response[0].team2score !== '') {
          const response5 = await getteamscorers(response[0].team2, matchId)
          setTeam2scorers(response5)
        }
      } else {
        navigate('/all-matches')
      }
    } else {
      navigate('/all-matches')
    }
  }

  useEffect(() => {
    if (matchId) {
      fetchMatch()
      fetchMatchWebsites()
    }
  }, [])

  useEffect(() => {
    if (matchId) {
      fetchMatchlinks(type)
    }
  }, [type])

  const handleSetType = async (t) => {
    setType(t)
    setlinksInput('')
    fetchMatchlinks(t)
  }

  const fetchCategories = async () => {
    const response = await getlinkcategories(type, matchId)
    setliveCount(response[0].totallivelinks)
    sethighlightCount(response[0].totalhighlightlinks)
    setCategories(response)
  }

  const fetchMatchlinks = async (type) => {
    setslinksLoading(true)
    const response = await getmatchlinks(type, matchId)
    setmatchLinks(response.matchlinks)
    setliveCount(response.totallive)
    sethighlightCount(response.totalhighlights)
    setgoogleCount(response.totalgoogle)
    setwebsitesLinks(response.totalwebsites)
    settotalSpectators(response.totalspectators)
    setslinksLoading(false)
  }

  const fetchMatchWebsites = async () => {
    const response = await getmatchwebsites(matchId)
    setWebsite(response)
    const response2 = await getspecwebsites(matchId)
    setspecWebsite(response2)
  }

  const handleLinksSubmit = async (e) => {
    e.preventDefault()
    if (!isLoading) {
      try {
        setlinkFormLoading(true)
        if (linksInput !== '') {
          setlinksError('')
          let response = await addmatchlinks(linksInput, type, matchId)
          if (response === 'success') {
            setlinksInput('')
            setshowLinkForm(false)
            fetchMatchlinks(type)
          }
        } else {
          setlinksError('All fields are required')
          return
        }
      } catch (error) {
        setlinksError('Error: ' + error)
      } finally {
        setlinkFormLoading(false)
      }
    }
  }

  const handleDelete = async (id) => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: 'Are you sure to delete?',
          text: "You won't be able to undo this action",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Delete',
        })

        if (result.isConfirmed) {
          let response = await deletematchlink(id)
          if (response === 'success') {
            fetchMatchlinks(type)
          }
        }
      } catch (error) {
        Swal.fire('An Error Occurred', error, 'error')
      }
    }
  }

  const handleScoreChange = (score, setScore, setSelects) => {
    const newScore = parseInt(score, 10) || 0
    setScore(newScore)
    let newSelects = {}
    for (let i = 0; i < newScore; i++) {
      newSelects[i] = ''
    }
    setSelects(newSelects)
  }

  const handleSelectChange = (selects, setSelects, index, value) => {
    setSelects((prev) => ({ ...prev, [index]: value }))
  }

  const handleScoreSubmit = async (e) => {
    e.preventDefault()
    if (!isLoading) {
      try {
        setscoreLoading(true)
        const team1Players = Object.values(team1Selects)
        const team2Players = Object.values(team2Selects)
        let response = await addmatchscore(matchId, team1score, team2score, team1Players, team2Players)
        if (response === 'success') {
          setscoreLoading(false)
          setshowScoreForm(false)
          fetchMatch()
        }
      } catch (error) {
        console.error('Error submitting scores:', error)
      } finally {
        setscoreLoading(false)
      }
    }
  }

  useEffect(() => {
    const newCounts = {}
    websites.forEach((site) => {
      newCounts[site.id] = site.totalspectators || 0
    })
    setSpectatorCounts(newCounts)
  }, [websites])

  const handleSpectatorChange = (id, value) => {
    setSpectatorCounts((prev) => ({ ...prev, [id]: value }))
    setshowSpecButton(true)
  }

  const handleSubmitSpectators = async () => {
    if (!isLoading) {
      try {
        setspecFormLoading(true)
        let response = await updatematchspectators(matchId, spectatorCounts)
        if (response === 'success') {
          setshowSpecButton(false)
        }
      } catch (error) {
      } finally {
        setspecFormLoading(false)
      }
    }
  }

  return (
    <AppLayout pageName='Match Details'>
      {match !== '' ? (
        <>
          <section>
            <div className='py-5'>
              <div className='flex justify-center space-x-4 mb-10'>
                <div className='flex space-x-2 font-normal tracking-wider items-center text-white p-2 rounded-full bg-main pr-3'>
                  <img
                    className='rounded-full border-4 border-white'
                    width='30'
                    height='30'
                    src={`/images/teams/${match.compname.toLowerCase().replaceAll(' ', '')}-sm.png`}
                  />{' '}
                  <span>{match.compname}</span>
                </div>
                <div className='text-lg tracking-wide p-2 px-5 rounded-full bg-white border text-main-alt border-main-alt'>
                  {match.status}
                </div>
              </div>
              <div className='flex flex-col md:flex-row justify-center space-x-24 heading'>
                <Link to={`/teams/${match.team1name.toLowerCase().replaceAll(' ', '')}/${match.team1}`}>
                  <div className='flex flex-col space-y-8 text-3xl font-bold text-main items-center text-center'>
                    <img width='100' height='100' src={getTeamImage(match.team1logo, match.team1name)} />{' '}
                    <div>{match.team1name}</div>
                  </div>
                </Link>
                <div className='flex flex-col space-y-3 text-3xl font-bold text-black text-center justify-center'>
                  {match.matchday !== '' && (
                    <div className='text-base tracking-wide text-neutral-400 font-light'>Journée {match.matchday}</div>
                  )}
                  <div className=''>{format(new Date(match.date), 'EEE, do MMM yyyy', { locale: fr })}</div>
                  <div className='flex justify-center'>
                    <div className='text-lg tracking-wide p-1 px-5 rounded-full bg-neutral-100 text-neutral-800'>
                      {match.time}
                    </div>
                  </div>
                  {match.team1score != null && match.team2score != null && (
                    <>
                      <div className='flex justify-center items-top space-x-5'>
                        <div>
                          <div
                            className={classNames({
                              'rounded py-2 w-12 text-center text-white': match.team1score != null,
                              'bg-main-alt': match.team1score > match.team2score,
                              'bg-red-700': match.team1score < match.team2score,
                              'bg-neutral-500': match.team1score !== null && match.team1score === match.team2score,
                            })}
                          >
                            {match.team1score}
                          </div>
                        </div>
                        <div className='text-lg font-bold text-neutral-500 pt-2.5'>-</div>
                        <div>
                          <div
                            className={classNames({
                              'rounded py-2 w-12 text-center text-white': match.team2score != null,
                              'bg-main-alt': match.team2score > match.team1score,
                              'bg-red-600': match.team2score < match.team1score,
                              'bg-neutral-500': match.team1score !== null && match.team1score === match.team2score,
                            })}
                          >
                            {match.team2score}
                          </div>
                        </div>
                      </div>
                      <div className='flex justify-center items-top'>
                        <div className='flex-1'>
                          {match.team1score > 0 && (
                            <div className='space-y-1 text-neutral-500 text-xs text-right font-normal normal-font'>
                              {team1scorers.map((scorer, index) => {
                                return (
                                  <div className='whitespace-nowrap' key={index}>
                                    {scorer.playername}{' '}
                                    {scorer.origteamid == match.team2 && (
                                      <span className='text-red-500'>(Own Goal)</span>
                                    )}
                                  </div>
                                )
                              })}
                            </div>
                          )}
                        </div>
                        <div className='text-lg font-bold text-neutral-500 w-11'></div>
                        <div className='flex-1'>
                          {match.team2score > 0 && (
                            <div className='space-y-1 text-neutral-500 text-xs text-left font-normal normal-font'>
                              {team2scorers.map((scorer, index) => {
                                return (
                                  <div className='whitespace-nowrap' key={index}>
                                    {scorer.playername}{' '}
                                    {scorer.origteamid == match.team1 && (
                                      <span className='text-red-500'>(Own Goal)</span>
                                    )}
                                  </div>
                                )
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {showScoreForm ? (
                    <>
                      <form onSubmit={handleScoreSubmit} className='score-form'>
                        <div className='flex justify-center items-top space-x-5'>
                          <div className='space-y-2 flex flex-col items-center'>
                            <input
                              id='team1score'
                              required
                              name='team1score'
                              type='number'
                              min={0}
                              className='form-control !w-12 !p-0'
                              value={team1score}
                              onChange={(e) => handleScoreChange(e.target.value, setTeam1score, setTeam1Selects)}
                            />
                            {Object.keys(team1Selects).map((index) => (
                              <div key={index}>
                                <select
                                  value={team1Selects[index]}
                                  onChange={(e) =>
                                    handleSelectChange(team1Selects, setTeam1Selects, index, e.target.value)
                                  }
                                  className='form-control'
                                  required
                                >
                                  <option value='' disabled selected>
                                    Select scorer
                                  </option>
                                  {team1players.map((player) => (
                                    <option key={player.playerid} value={player.playerid}>
                                      {player.name}
                                    </option>
                                  ))}
                                  <optgroup label='if own goal'>
                                    {team2players.map((player) => (
                                      <option key={player.playerid} value={player.playerid}>
                                        {player.name}
                                      </option>
                                    ))}
                                  </optgroup>
                                </select>
                              </div>
                            ))}
                          </div>
                          <div className='text-lg font-bold text-neutral-500 pt-1.5'>-</div>
                          <div className='space-y-2 flex flex-col items-center'>
                            <input
                              id='team2score'
                              required
                              name='team2score'
                              type='number'
                              min={0}
                              className='form-control !w-12 !p-0'
                              value={team2score}
                              onChange={(e) => handleScoreChange(e.target.value, setTeam2score, setTeam2Selects)}
                            />
                            {Object.keys(team2Selects).map((index) => (
                              <div key={index}>
                                <select
                                  value={team2Selects[index]}
                                  onChange={(e) =>
                                    handleSelectChange(team2Selects, setTeam2Selects, index, e.target.value)
                                  }
                                  className='form-control'
                                  required
                                >
                                  <option value='' disabled selected>
                                    Select scorer
                                  </option>
                                  {team2players.map((player) => (
                                    <option key={player.playerid} value={player.playerid}>
                                      {player.name}
                                    </option>
                                  ))}
                                  <optgroup label='if own goal'>
                                    {team1players.map((player) => (
                                      <option key={player.playerid} value={player.playerid}>
                                        {player.name}
                                      </option>
                                    ))}
                                  </optgroup>
                                </select>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className='flex justify-center space-x-2 mt-3'>
                          <button
                            type='button'
                            onClick={() => setshowScoreForm(!showScoreForm)}
                            className='bg-green-100 hover:bg-green-200 transition-colors text-main-alt text-xs font-medium px-2.5 py-2 rounded normal-font'
                          >
                            {showScoreForm ? 'Cancel' : 'Update Score'}
                          </button>
                          {showScoreForm && (
                            <button
                              type='submit'
                              className='bg-main transition-colors text-white text-xs font-medium px-2.5 py-2 rounded normal-font'
                            >
                              Save
                            </button>
                          )}
                        </div>
                      </form>
                    </>
                  ) : (
                    <>
                      <div className='flex justify-center space-x-2'>
                        <button
                          onClick={() => setshowScoreForm(!showScoreForm)}
                          className='bg-green-100 hover:bg-green-200 transition-colors text-main-alt text-xs font-medium px-2.5 py-2 rounded normal-font'
                        >
                          {showScoreForm ? 'Cancel' : 'Update Score'}
                        </button>
                      </div>
                    </>
                  )}
                </div>
                <Link to={`/teams/${match.team2name.toLowerCase().replaceAll(' ', '')}/${match.team2}`}>
                  <div className='flex flex-col space-y-8 text-3xl font-bold text-main items-center text-center'>
                    <img width='100' height='100' src={getTeamImage(match.team2logo, match.team2name)} />{' '}
                    <div>{match.team2name}</div>
                  </div>
                </Link>
              </div>
            </div>
          </section>
          <section>
            <div className='flex flex-col lg:flex-col justify-between items-center space-x-3'>
              <div className='text-center'>
                <h2 className='text-2xl font-bold tracking-normal text-main mb-2'>Liens du match</h2>
                <div className='flex flex-row items-center space-x-3 mb-2'>
                  <div>
                    <span className='bg-blue-100 text-blue-800 text-sm font-medium px-3 py-2 rounded'>
                      Total des liens:{' '}
                      {liveCount + highlightCount + googleCount > 0
                        ? (liveCount + highlightCount + googleCount).toLocaleString()
                        : '0'}
                    </span>
                  </div>
                  <div>
                    <span className='bg-green-100 text-green-800 text-sm font-medium px-3 py-2 rounded'>
                      Nombre de spectateurs: {totalSpectators > 0 ? totalSpectators.toLocaleString() : 0}
                    </span>
                  </div>
                </div>
              </div>
              {websitesLinks && (
                <div className='flex flex-wrap flex-row items-center justify-center mb-4'>
                  {websitesLinks.map((website, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className='flex space-x-2 border rounded p-1 pr-2 m-2 items-center'>
                          <div>
                            <img width='40' height='40' src={`/images/icons/${website.icon}.png`} />
                          </div>
                          <div>
                            <h6 className='text-neutral-500 text-xs'>{website.name}</h6>
                            <h5 className='text-main-alt leading-none mt-0.5 font-bold'>
                              {website.name == 'Google' || website.name == 'Bing' ? googleCount : website.total}
                            </h5>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })}
                </div>
              )}
            </div>
            <div className='pills w-full rounded-lg p-0 text-black font-semibold flex'>
              <button
                className={`${
                  type === 'Live' ? 'bg-white border border-b-white' : 'hover:bg-neutral-50 border-b text-neutral-500'
                } rounded-t-lg w-1/3 p-4 flex justify-center space-x-2`}
                onClick={() => handleSetType('Live')}
              >
                <span>Live</span>
                {liveCount > 0 && (
                  <span className='bg-green-100 text-green-800 text-sm font-medium px-2.5 py-0.5 rounded'>
                    {liveCount}
                  </span>
                )}
              </button>
              <button
                className={`${
                  type === 'Highlight'
                    ? 'bg-white border border-b-white'
                    : 'hover:bg-neutral-50 border-b text-neutral-500'
                } rounded-t-lg w-1/3 p-4 flex justify-center space-x-2`}
                onClick={() => handleSetType('Highlight')}
              >
                <span>Highlights</span>
                {highlightCount > 0 && (
                  <span className='bg-green-100 text-green-800 text-sm font-medium px-2.5 py-0.5 rounded'>
                    {highlightCount}
                  </span>
                )}
              </button>
              <button
                className={`${
                  type === 'Google' ? 'bg-white border border-b-white' : 'hover:bg-neutral-50 border-b text-neutral-500'
                } rounded-t-lg w-1/3 p-4 flex justify-center space-x-2`}
                onClick={() => handleSetType('Google')}
              >
                <span>Google & Bing</span>
                {googleCount > 0 && (
                  <span className='bg-green-100 text-green-800 text-sm font-medium px-2.5 py-0.5 rounded'>
                    {googleCount}
                  </span>
                )}
              </button>
            </div>
            <section className='space-y-6 !rounded-t-none !border-t-0'>
              <button onClick={() => setshowLinkForm(!showLinkForm)} className='btn-main-inverse mb-3'>
                New Links <FiPlus />
              </button>
              {showLinkForm && (
                <section>
                  <form onSubmit={handleLinksSubmit} method='post'>
                    <div className='p-2'>
                      <textarea
                        name='linksinput'
                        rows={8}
                        className='form-control'
                        placeholder='Copy and paste the links here...'
                        required
                        onChange={(e) => setlinksInput(e.target.value)}
                      ></textarea>
                    </div>
                    <div className='p-2'>
                      <div className='flex items-center p-4 border-l-4 border-neutral-400 bg-neutral-100' role='alert'>
                        <svg
                          className='flex-shrink-0 w-4 h-4'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                        >
                          <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z' />
                        </svg>
                        <div className='ms-3 text-sm font-medium text-gray-700'>
                          Please make sure each link is in a separate line.
                        </div>
                      </div>
                    </div>
                    <div className='p-2 flex space-x-5 items-center'>
                      <button type='submit' className='btn-submit' disabled={isLoading}>
                        <span>Submit</span>
                        <span>
                          {linkFormLoading === false ? (
                            <HiArrowNarrowRight />
                          ) : (
                            <Spinner size={14} className='ml-2' animating={linkFormLoading} />
                          )}
                        </span>
                      </button>
                      {linksError !== '' ? (
                        <div className='text-red-500 font-semibold text-md tracking-wide'>{linksError}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </form>
                </section>
              )}

              <div>
                {linksLoading ? (
                  <div className='flex justify-center w-full py-12'>
                    <Spinner color='#CCCCCC' size={24} />
                  </div>
                ) : (
                  <div className='text-base font-normal text-neutral-800 space-y-2'>
                    {matchLinks.map((link, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className='flex flex-row'>
                            <div className='flex-1 truncate'>
                              <a href={link.link} target='_blank' rel='noreferrer' className='w-full'>
                                <div className='flex w-full space-x-1 py-2 px-3 truncate bg-white rounded-l items-center hover:bg-neutral-200'>
                                  <IoIosArrowForward size={14} /> <span className='truncate'>{link.link}</span>
                                </div>
                              </a>
                            </div>
                            <button
                              className='bg-white rounded-r hover:bg-neutral-200 p-3'
                              onClick={() => handleDelete(link.id)}
                            >
                              <RiDeleteBin5Line className='text-red-500' />
                            </button>
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </div>
                )}
              </div>

              {/* 
              {categories.map((category, index) => {
                let catname = category.name.replaceAll(" ", "");
                return (
                  <>
                    <div className="border rounded-lg" key={index}>
                      <div className="p-5 rounded-lg text-lg text-neutral-800 font-medium flex justify-between items-center bg-white hover:bg-neutral-50 cursor-pointer" onClick={() => linkCatcollapse(category.id)}>
                        <div className="flex space-x-3">
                          <span>{category.name}</span>
                          {category.totallinks > 0 && <span className="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded">{category.totallinks}</span>}
                        </div>
                        {isOpen === category.id ? <IoIosArrowUp /> : <IoIosArrowDown />}
                      </div>
                      {isOpen === category.id && (
                        <div className="p-7 bg-neutral-100 text-neutral-700">

                          <div>
                            <div>
                              <div className="text-base font-normal text-neutral-800 space-y-2">
                                {matchLinks.map((link, index) => {
                                  return (
                                    <>
                                      <div className="flex flex-row">
                                        <div className="flex-1 truncate">
                                          <a href={link.link} target="_blank" rel="noreferrer" className="w-full">
                                            <div className="flex w-full space-x-1 py-2 px-3 truncate bg-white rounded-l items-center hover:bg-neutral-200">
                                              <IoIosArrowForward size={14} /> <span className="truncate">{link.link}</span>
                                            </div>
                                          </a>
                                        </div>
                                        <button className="bg-white rounded-r hover:bg-neutral-200 p-3" onClick={() => handleDelete(link.id, category.id)}>
                                          <RiDeleteBin5Line className="text-red-500" />
                                        </button>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                );
              })}
              */}
            </section>
          </section>
          <section>
            <h2 className='text-2xl font-bold tracking-normal text-main'>Match Spectators</h2>
            <table className='table mt-3'>
              <thead>
                <tr>
                  <th scope='col' className='px-6 py-4 text-left'>
                    Plateformes
                  </th>
                  <th scope='col' className='px-6 py-4'>
                    Spectators
                  </th>
                </tr>
              </thead>
              <tbody>
                {specwebsites.map((website, index) => {
                  return (
                    <tr key={website.id} className='border-t'>
                      <td scope='row' className='px-6 py-4 font-medium whitespace-nowrap text-left'>
                        <div className='flex space-x-2 items-center'>
                          <img width='16' height='16' src={`/images/icons/${website.icon}.png`} />
                          <h6 className='text-neutral-900 text-lg'>{website.name}</h6>
                        </div>
                      </td>
                      <td className='px-6 py-3 text-lg text-center'>
                        <input
                          className='form-control py-1 text-center border'
                          value={spectatorCounts[website.id] || 0}
                          onChange={(e) => handleSpectatorChange(website.id, parseInt(e.target.value))}
                        />
                      </td>
                    </tr>
                  )
                })}
                <tr>
                  <td></td>
                  <td className='px-6 py-3 text-lg text-center'>
                    {showSpecButton && (
                      <button onClick={handleSubmitSpectators} className='btn-submit mx-auto'>
                        Sauvegarder les modifications
                      </button>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </>
      ) : (
        <div className='flex justify-center w-full py-12'>
          <Spinner color='#CCCCCC' size={24} />
        </div>
      )}
    </AppLayout>
  )
}

export default MatchDetails
