import React, { Fragment, useContext, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import AppLayout from '../layouts/AppLayout'
import { AppContext } from '../contexts/AppContext'
import { Link } from 'react-router-dom'
import Spinner from 'react-activity/dist/Spinner'
import 'react-activity/dist/Spinner.css'
import { FiEdit } from 'react-icons/fi'
import { RiDeleteBinLine } from 'react-icons/ri'
import { TbSwitchHorizontal } from 'react-icons/tb'
import Tooltip from '../components/Tooltip'
import { RxCross2 } from 'react-icons/rx'
import { getTeamImage } from '../utils/team'

const AllTeams = () => {
  const { getteams, switchteam, userToken, isLoading } = useContext(AppContext)

  const [ligue1, setLigue1] = useState([])
  const [ligue2, setLigue2] = useState([])
  const [sLoading, setsLoading] = useState(false)
  const [toSwitch, setToSwitch] = useState('')
  const [modalopen, setModalopen] = useState(false)
  const [steam, setsTeam] = useState('')
  const [teamToSwitch, setTeamToSwitch] = useState(null)
  const [ligue, setLigue] = useState('')

  const fetchTeams = async () => {
    const response = await getteams(1)
    setLigue1(response)

    const response2 = await getteams(2)
    setLigue2(response2)
  }

  const switchTeam = async () => {
    setsLoading(true)
    let response = await switchteam(toSwitch)
    setsLoading(false)
    if (response) {
      setModalopen(false)
      setTeamToSwitch(null)
      setLigue('')
      fetchTeams()
    }
  }

  const handleTeamSwitch = async (teamToSwitchParam, c, team) => {
    setToSwitch(team)
    setTeamToSwitch(teamToSwitchParam)
    setLigue(c)
    setModalopen(true)
  }

  const closeModal = () => {
    setModalopen(false)
    setsTeam('')
  }

  useEffect(() => {
    fetchTeams()
  }, [])

  return (
    <AppLayout pageName='Toutes les équipes'>
      {isLoading ? (
        <section>
          <div className='flex justify-center w-full py-12'>
            <Spinner color='#CCCCCC' size={24} />
          </div>
        </section>
      ) : (
        <>
          <Transition.Root show={modalopen} as={Fragment}>
            <Dialog as='div' className='relative z-50' onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 hidden bg-gray-50 bg-opacity-75 blur-xl transition-opacity md:block' />
              </Transition.Child>

              <div className='fixed inset-0 z-50 overflow-y-auto'>
                <div className='flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 md:translate-y-0 md:scale-95'
                    enterTo='opacity-100 translate-y-0 md:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 md:scale-100'
                    leaveTo='opacity-0 translate-y-4 md:translate-y-0 md:scale-95'
                  >
                    <Dialog.Panel className='flex w-full transform text-left text-base transition md:my-8 md:max-w-lg md:px-4 lg:max-w-lg'>
                      <div className='relative w-full items-center overflow-hidden bg-white border border-main rounded-lg text-neutral-800 shadow-2xl p-8'>
                        <div className='w-full space-y-2'>
                          <h5 className='text-xl font-medium'>Switch Team</h5>
                          <div
                            className={`p-3 rounded-lg bg-neutral-50 hover:bg-neutral-100 border transition-colors relative cursor-pointer`}
                          >
                            <div className='flex flex-row space-x-4 items-center'>
                              {teamToSwitch && (
                                <img
                                  className='w-12 h-12 rounded-md'
                                  src={getTeamImage(teamToSwitch?.logo, teamToSwitch?.name)}
                                  alt={teamToSwitch?.name?.toLowerCase().replaceAll(' ', '')}
                                />
                              )}
                              {/* <div className={`w-12 h-12 rounded-md ${colorsarr[positionarr.indexOf(sposition)]}`}></div> */}
                              <div>
                                <div className='text-neutral-500 font-medium tracking-wide uppercase text-xs'>
                                  {ligue}
                                </div>
                                <div className='heading text-xl tracking-wide font-semibold text-black'>
                                  {teamToSwitch?.name}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={`p-3 rounded-lg bg-neutral-50 border transition-colors relative`}>
                            <div className='flex flex-row space-x-4 items-center'>
                              <img
                                className='w-12 h-12 rounded-md'
                                src={`/images/${ligue == 'Ligue 1' ? 'ligue2' : 'ligue1'}-sm.png`}
                              />
                              {/* <div className={`w-12 h-12 rounded-md ${colorsarr[positionarr.indexOf(sposition)]}`}></div> */}
                              <div>
                                <div className='text-neutral-500 font-medium tracking-wide uppercase text-xs'>
                                  Switch to
                                </div>
                                <div className='heading text-xl tracking-wide font-semibold text-black'>
                                  Ligue {ligue == 'Ligue 1' ? '2' : '1'}
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            type='button'
                            className='absolute top-3 right-3 text-gray-400 hover:text-gray-500'
                            onClick={() => closeModal()}
                          >
                            <span className='sr-only'>Close</span>
                            <RxCross2 className='h-5 w-5' aria-hidden='true' />
                          </button>
                        </div>
                        <div className='pt-7 mb-3 flex justify-end space-x-2'>
                          <button disabled={sLoading} className='btn-cancel' onClick={() => closeModal()}>
                            No, Cancel
                          </button>
                          <button disabled={sLoading} className='btn-submit !px-6' onClick={() => switchTeam()}>
                            {sLoading === false ? 'Yes, Switch' : <Spinner animating={sLoading} />}
                          </button>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          <section className='teams'>
            <div className='flex sm:flex-row flex-col justify-between items-start'>
              <div className='w-full md:w-1/2 mb-4'>
                <img height='100' className='rounded-lg' alt='Ligue 1' src={`/images/teams/ligue1.png`} />
              </div>
              <Link to={'/teams/create'}>
                <button className='btn-main mb-2'>créer une équipe</button>
              </Link>
            </div>
            <div className='grid grid-cols-2 gap-4'>
              {ligue1.map((team, index) => {
                return (
                  <div key={index} className='relative'>
                    <Link to={`/teams/${team.name.toLowerCase().replaceAll(' ', '')}/${team.id}`}>
                      <div
                        className={`p-4 hover:bg-neutral-50 border-2 rounded-lg border-neutral-100 cursor-pointer relative`}
                      >
                        <div className='flex space-x-2 text-base font-medium items-center text-neutral-800'>
                          <img
                            width='30'
                            height='30'
                            src={getTeamImage(team?.logo, team?.name)}
                            alt={team.name.toLowerCase().replaceAll(' ', '')}
                          />
                          <span>{team.name}</span>
                        </div>
                      </div>
                    </Link>
                    {userToken.type == 'Admin' && (
                      <div className='absolute right-0 top-0 h-full grid grid-rows-3'>
                        {/* <button className="flex-1 p-1 bg-white rounded-tr border" onClick={() => handleTeamsEdit(team.id)}><Tooltip text="edit" customClass="!bg-blue-400"><FiEdit className="text-blue-500 text-xs" /></Tooltip></button> */}
                        <button
                          className='flex-1 p-1 bg-white border'
                          onClick={() => handleTeamSwitch(team, 'Ligue 1', team.id)}
                        >
                          <Tooltip text='switch' customClass='!bg-neutral-400'>
                            <TbSwitchHorizontal className='text-neutral-500 text-xs' />
                          </Tooltip>
                        </button>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </section>
          <section className='teams'>
            <div className='flex sm:flex-row flex-col justify-between items-start'>
              <div className='w-full md:w-1/2 mb-4'>
                <img height='100' className='rounded-lg' alt='Ligue 2' src={`/images/teams/ligue2.png`} />
              </div>
              <Link to={'/teams/create'}>
                <button className='btn-main mb-2'>créer une équipe</button>
              </Link>
            </div>
            <div className='grid grid-cols-2 gap-4'>
              {ligue2.map((team, index) => {
                return (
                  <div key={index} className='relative'>
                    <Link to={`/teams/${team.name.toLowerCase().replaceAll(' ', '')}/${team.id}`}>
                      <div
                        className={`p-4 hover:bg-neutral-50 border-2 rounded-lg border-neutral-100 cursor-pointer relative`}
                      >
                        <div className='flex space-x-2 text-base font-medium items-center text-neutral-800'>
                          <img
                            width='30'
                            height='30'
                            src={getTeamImage(team?.logo, team?.name)}
                            alt={team.name.toLowerCase().replaceAll(' ', '')}
                          />{' '}
                          <span>{team.name}</span>
                        </div>
                      </div>
                    </Link>
                    {userToken.type == 'Admin' && (
                      <div className='absolute right-0 top-0 h-full grid grid-rows-3 z-10'>
                        {/* <button className="flex-1 p-1 bg-white rounded-tr border" onClick={() => handleTeamsEdit(team.id)}><Tooltip text="edit" customClass="!bg-blue-400"><FiEdit className="text-blue-500 text-xs" /></Tooltip></button> */}
                        <button
                          className='flex-1 p-1 bg-white border'
                          onClick={() => handleTeamSwitch(team, 'Ligue 2', team.id)}
                        >
                          <Tooltip text='switch' customClass='!bg-neutral-400'>
                            <TbSwitchHorizontal className='text-neutral-500 text-xs' />
                          </Tooltip>
                        </button>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </section>
        </>
      )}
    </AppLayout>
  )
}

export default AllTeams
